import { createAction, createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { Download, ErrorMessage, ErrorType, FileProgressType, FilesList, UploadedFile } from "../types/StandardFilesTranslateTypes";
import { ConvertFileRequest, ConvertFileResponse, CostEstimationResponse, PrepareStripeCheckoutResponse } from "../connector/models/apiModels";
import Enumerable from "linq";

export const FILE_FEATURE_KEY = "file";

export interface FileValues {
  fileId: string;
  fileListId: string;
}

/*Generators*/
export const generateConvertFileAction = createAction<ConvertFileRequest>("file/generateConvertFileAction");
/**
 * If user dont have enough words for machine translation he can pay for it with Stripe
 */
export const generatePayForMachineTranslationAction = createAction("file/generatePayForMachineTranslationAction");
export const generateDeleteDuplicatedFileFromListAction = createAction("file/generateDeleteDuplicatedFileFromListAction");
export const generateUpdatePdfFileTypeAction = createAction<{
  filedId: string;
  pdfType: number;
}>("file/generateUpdatePdfFileTypeAction");

export interface FileEntity extends UploadedFile {}

export interface FileState extends EntityState<FileEntity> {
  projectId: string;
  isLoading: boolean;
  show: boolean;
  typeError: boolean;
  fileCountError: boolean;
  wordsCountError: boolean;
  fileSizeError: boolean;
  success: boolean;
  translatedFile: Download;
  error: string;
  filesListData: FilesList[];
  totalWordCount: number;
  costEstimations: CostEstimationResponse;
  translationCost: string;
  service: string;
  stripeSession: PrepareStripeCheckoutResponse;
  translationIsReady: boolean;
  textUnitedProjectId: string | null;
  isOfferRequired: boolean;
  wordsAllowance: number;
  pdfFilesPages: number;
  pdfAllowance: number;
}

export const fileAdapter = createEntityAdapter<FileEntity>({
  selectId: (file) => file.fileId,
});

export const initialFileState: FileState = fileAdapter.getInitialState({
  projectId: "",
  isLoading: false,
  show: false,
  fileCountError: false,
  fileSizeError: false,
  success: false,
  typeError: false,
  wordsCountError: false,
  translatedFile: "" as any,
  loadingStatus: "not loaded",
  error: "",
  filesListData: [],
  totalWordCount: 0,
  costEstimations: {} as CostEstimationResponse,
  translationCost: "",
  service: "Instant",
  stripeSession: {} as PrepareStripeCheckoutResponse,
  translationIsReady: false,
  textUnitedProjectId: null,
  isOfferRequired: false,
  wordsAllowance: 0,
  pdfFilesPages: 0,
  pdfAllowance: 0,
});

export const fileSlice = createSlice({
  name: FILE_FEATURE_KEY,
  initialState: initialFileState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setPdfPagesNumber: (state, action: PayloadAction<number>) => {
      state.pdfFilesPages += action.payload;
    },
    setTotalWordsCount: (state, action: PayloadAction<number>) => {
      if(action.payload < 0){
        state.totalWordCount = action.payload;
        }
    },
    setWordsAllowance: (state, action: PayloadAction<number>) => {
      state.wordsAllowance = action.payload;
    },
    setPdfAllowance: (state, action: PayloadAction<number>) => {
      state.pdfAllowance = action.payload;
    },
    setOfferRequiredStatus: (state, action: PayloadAction<boolean>) => {
      state.isOfferRequired = action.payload;
    },
    setTextUnitedProjectId: (state, action: PayloadAction<string>) => {
      state.textUnitedProjectId = action.payload;
    },
    changeTranslationStatus: (state, action: PayloadAction<boolean>) => {
      state.translationIsReady = action.payload;
    },
    addStripeSessionId: (state, action: PayloadAction<PrepareStripeCheckoutResponse>) => {
      state.stripeSession = action.payload;
    },

    addTranslationOption: (state, action: PayloadAction<string>) => {
      const option = action.payload.split(",");
      state.translationCost = option[0].trim();
      if(option[1] != undefined){
        state.service = option[1].trim();
      }
      else{
        state.service = option[0].trim();
      }

    },
    addCostEstimation: (state, action: PayloadAction<CostEstimationResponse>) => {
      state.costEstimations = action.payload;
      if (action.payload.machineTranslationCost < 1) {
        state.costEstimations.machineTranslationCost = 1;
      }
      state.isLoading = false;
    },
    addFileError: (state, action: PayloadAction<ErrorMessage>) => {
      const error = state.filesListData.find((x) => x.fileName == action.payload.fileName);
      if(error != undefined){
        error!.errorMsg.content = action.payload.content;
        error!.errorMsg.color = action.payload.color;
        error!.errorMsg.errorType = action.payload.errorType;
        switch (action.payload.errorType) {
          case 1:
            error!.warning = true;
            break;
          case 2:
            error!.error = true;
            break;
          default:
            break;
        }
        error!.isLoading = false;
      }

    },
    addFilesName: (state, action: PayloadAction<FilesList[]>) => {
      if (state.filesListData.length == 0) {
        state.filesListData = action.payload;
      } else {
        state.filesListData = state.filesListData.concat(action.payload);
      }

      state.show = true;
    },
    deleteFileFromList: (state, action: PayloadAction<FileValues>) => {
      if (action.payload.fileId != "") {
        const t = Enumerable.from(state.entities).first((x) => x.value!.fileId == action.payload.fileId);
        fileAdapter.removeOne(state, action.payload.fileId);
        const wordsCountLeft = (state.totalWordCount -= t.value!.wordcount);
        const fileList = state.filesListData.find((x) => x.fileName == t.value!.fileName);
        fileList!.errorMsg.errorType = 0;
        state.totalWordCount = wordsCountLeft;
        const isStillLoading = Enumerable.from(state.filesListData).any((x) => x.isLoading == true);
        if (wordsCountLeft == 0 && !isStillLoading) {
          state.show = false;
        }
      }
      const isStillLoading = Enumerable.from(state.filesListData).any((x) => x.isLoading == true);
      if (state.totalWordCount == 0 && !isStillLoading) {
        state.show = false;
        state.projectId = "";
      }
      state.filesListData = state.filesListData.filter((el) => el.id != action.payload.fileListId);
    },
    setFileProgress: (state, action: PayloadAction<FileProgressType>) => {
      const file = Enumerable.from(state.filesListData).first((x) => x.fileName == action.payload.fileName);
      if (file != undefined) {
        file.progress = action.payload.progress;
      }
    },
    deleteDuplicatedFileFromList: (state, action: PayloadAction<string>) => {
      state.filesListData = state.filesListData.filter((el) => (el.fileName == action.payload && el.isLoading == false) || el.fileName != action.payload);
    },
    setProjectId: (state, action: PayloadAction<string>) => {
      state.projectId = action.payload;
    },
    addFile: (state, action: PayloadAction<ConvertFileResponse>) => {
      const isLoaded = state.filesListData.find((x) => x.fileName == action.payload.fileName);
      if(isLoaded != undefined){
        isLoaded!.isLoading = false;
        isLoaded!.fileId = action.payload.fileId;
        state.totalWordCount += action.payload.wordcount;
        fileAdapter.addOne(state, action.payload);
      }

    },
    showEditor: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
    downloadTranslatedUserFile: (state, action: PayloadAction<Download>) => {
      state.translatedFile = action.payload;
      state.isLoading = false;
      state.success = true;
    },
    showFileCountError: (state, action: PayloadAction<boolean>) => {
      state.fileCountError = action.payload;
    },
    showFileWordsCountError: (state, action: PayloadAction<boolean>) => {
      state.wordsCountError = action.payload;
      state.show = false;
    },
    showFileTypeError: (state, action: PayloadAction<boolean>) => {
      state.typeError = action.payload;
      state.show = false;
    },
    closeErrorWindow: (state) => {
      state.typeError = false;
      state.fileCountError = false;
      state.wordsCountError = false;
      state.fileSizeError = false;
    },
    /**
     * this method set error on pdf files in there ara files with different format
     * @param state
     */
    setErrorOnPdfFilesOnMixing: (state) => {
      state.filesListData.map((x) => {
        if (x.fileName.includes(".pdf")) {
          x.error = true;
          x!.errorMsg.content = 'Error while trying to precess your file",';
          x!.errorMsg.color = "#f44336";
          x!.errorMsg.errorType = ErrorType.processingError;
        }
      });
    },
  },
});

/*
 * Export reducer for store configuration.
 */
export const fileReducer = fileSlice.reducer;

export const fileActions = fileSlice.actions;

const { selectAll, selectEntities } = fileAdapter.getSelectors();

export const getFileState = (rootState: RootState): FileState => rootState[FILE_FEATURE_KEY];

// export const getUploadFileState = (rootState: RootState): UploadedFile[] =>
//   rootState[FILE_FEATURE_KEY].files;

export const selectAllFile = createSelector(getFileState, selectAll);

export const selectFileEntities = createSelector(getFileState, selectEntities);
