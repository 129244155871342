import React, { useEffect } from "react";
import { ControlTypes, FilesList, LanguagesTypes } from "../types/StandardFilesTranslateTypes";
import { AppDispatch, RootState } from "../store/store";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { FadeLoader } from "react-spinners";
import Error from "./Error";
import "animate.css";
import { fileActions, FileEntity, FileState, getFileState, selectAllFile } from "../reducers/file.slice";
import {
  controlActions,
  generateCheckRedirectAction,
  generateSetAnimationOnNextStepAction,
  getControlState
} from "../reducers/control.slice";
import { selectAllLanguages } from "../reducers/languages.slice";
import ReactGA from "react-ga";
import { checkToken } from "../helpers/TokenHelper";
import LoadingScreen from "./LoadingScreen";
import DragNDrop from "./Drag-n-Drop";
import InfoIcon from "./svgIcons/InfoIcon";
import FilesListRow from "./FilesListRow";
import { Alert, Fade } from "@mui/material";
import { generateGetSubscriptionDataAction } from "../reducers/subscription.slice";

interface fileProps {
  file: FileState;
  filesList: FilesList[];
  languages: LanguagesTypes[];
  control: ControlTypes;
  transUnits: FileEntity[];

  onShowFileSizeError?(show: boolean): void;
  setAnimation?(animationClass: string): void;
  onShowFileCountError?(show: boolean): void;
  onTypeError?(show: boolean): void;
  onWordsCountError?(show: boolean): void;
  onNextStepClick?(step: number): void;
  onLanguageDetect?(text: string): void;
  onFilesUploadPopUpClick?(show: boolean): void;
  checkRedirects?(): void;
  checkUserSubscription?():void;
}

export function mapStateToProps(state: RootState): fileProps {
  return {
    file: getFileState(state),
    languages: selectAllLanguages(state),
    control: getControlState(state),
    transUnits: selectAllFile(state),
    filesList: getFileState(state).filesListData,
  };
}

export function matDispatchToProps(dispatch: AppDispatch) {
  return {
    setAnimation: (animationClass: string) => dispatch(controlActions.setAnimationClass(animationClass)),
    onShowFileCountError: (show: boolean) => dispatch(fileActions.showFileCountError(show)),
    onTypeError: (show: boolean) => dispatch(fileActions.showFileTypeError(show)),
    onWordsCountError: (show: boolean) => dispatch(fileActions.showFileWordsCountError(show)),
    onNextStepClick: (activeStep: number) => dispatch(generateSetAnimationOnNextStepAction({ activeStep })),
    onFilesUploadPopUpClick: (show: boolean) => dispatch(controlActions.showFileLoadingPopUp(show)),
    checkUserSubscription: () => dispatch(generateGetSubscriptionDataAction()),
    checkRedirects: () => dispatch(generateCheckRedirectAction())
  };
}

const UploadFile: React.FC<fileProps> = (props: fileProps) => {
  //
  //Handlers
  useEffect(() => {
    if(props.languages.length == 0){
      props.checkRedirects!();
      props.checkUserSubscription!();
    }

  }, []);
  const nextClickHandler = () => {
    const isAnonymous = checkToken(sessionStorage.getItem("token") as string);
    let wordsCount = 0;
    //addCurrentEditorState()
    if (wordsCount > 500 && isAnonymous) {
      props.onWordsCountError?.(true);
      props.setAnimation?.("animate__fadeInDown");
      setTimeout(() => {
        props.setAnimation?.("animate__fadeOut");
      }, 8000);
      setTimeout(() => {
        props.onWordsCountError?.(false);
      }, 9000);
      return;
    } else {
      props.onNextStepClick!(1);
    }
  };
  const typeErrorHandler = () => {
    props.onTypeError?.(true);
    props.setAnimation?.("animate__fadeInDown");
    setTimeout(() => {
      props.setAnimation?.("animate__fadeOut");
    }, 8000);
    setTimeout(() => {
      props.onTypeError?.(false);
    }, 9000);
  };

  const onUploadFileHandler = (e: File[]): void => {
    if (e.length > 1) {
      props.onShowFileCountError?.(true);
      props.setAnimation!("animate__fadeInDown");
      setTimeout(() => {
        props.setAnimation?.("animate__fadeOut");
      }, 8000);
      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsText(e[0], "utf-8");
    try {
      fileReader.onload = () => {
        props.onLanguageDetect?.(fileReader.result as string);
        ReactGA.event({
          category: "TextUnited Fast Translate",
          action: "upload file",
        });
      };
    } catch (e) {}
  };

  return (
    <>
      {props.file?.isLoading ? (
        <LoadingScreen />

      ) : (
        <div className="card upload-file">
          <>
            <div className="row">
              <div className="upload-text">
                <h2>
                  <span>Upload</span> files
                </h2>
              </div>
            </div>
            <Fade
              className={"xl:h-24"}
              in={props.control.showFileLoadingPopUp}
              onClick={() => props.onFilesUploadPopUpClick!(false)}
              style={{ transformOrigin: "0 0 0" }}
              {...(props.control.showFileLoadingPopUp ? { timeout: 1000 } : { timeout: 1000 })}
            >
              <Alert severity="info" hidden={!props.control.showFileLoadingPopUp} className={`info-popup`}>
                It may take a few moments, depending on the file size and format.
              </Alert>
            </Fade>
            <Fade in={props.control.showFileSizePopUp} style={{ transformOrigin: "0 0 0" }} {...(props.control.showFileSizePopUp ? { timeout: 1000 } : { timeout: 1000 })}>
              <Alert severity="error" hidden={!props.control.showFileSizePopUp} className={`info-popup`}>
                {props.control.popUpTitle}
              </Alert>
            </Fade>

            {/*{props.file?.wordsCountError && <Error text={"Your file contains more than 50 words"} className={props.control.animation}></Error>}*/}
            {/*{props.file?.fileSizeError && <Error className={props.control.animation} text={"File size cannot exceed 50kB"}></Error>}*/}
            {/*<Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} autoHideDuration={5000} open={true} onClose={() => ("test")} message="I love snacks" key={"top center"} />*/}
            <Error className={props.control.animation} show={props.control.showError} text={"You can upload only ten files at a time, if you not logged in. Please log in to upload more than ten" + " files"}></Error>
            {/*{props.file?.typeError && <Error className="test" text={"This is not a PO file"}></Error>}*/}
            {!props.file?.show && !props.control.showError && (
              <div>
                {!props.file?.isLoading ? (
                  <>
                    <div className="row ">
                      <DragNDrop class={"dropzone drag-n-drop animate__animated animate__fadeIn"} />
                    </div>
                    <div className="row">
                      <div className="info-msg text-center animate__animated animate__fadeInDown">
                        <InfoIcon /> a free translation <span>up to 10.000 words</span> for a new user
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="loader text-center">
                        <div className="row upload-loading-scrn">
                          <FadeLoader height={20} width={7} radius={10} margin={12} color={"#43B4E9"}></FadeLoader>
                          <span className="load-text">Preparing your file...</span>
                        </div>
                      </div>
                      <div className="info-msg text-center">
                        <InfoIcon /> a free translation <span>up to 2000 words</span> for a new user
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {props.file?.show && (
              <>
                <div className={`animate__animated `}>
                  <div className={`segments-section px-2`}>
                    {props.filesList.map((file) => (
                      // <Row segment={row.transUnits.segmentText} key={row.transUnits.id}  />
                      <FilesListRow file={file} key={file.id} filesCount={props.filesList.length} />
                    ))}
                    <div className="card" style={{ height: "50px" }}>
                      <DragNDrop class={"drag-n-drop-files-list"} />
                    </div>
                  </div>
                  <div className="info-msg text-center">
                    {props.file.totalWordCount == 0 ? null : (
                      <>
                        <InfoIcon /> Total word count <span>{props.file.totalWordCount}</span>
                      </>
                    )}
                    {props.file.totalWordCount == 0 && props.file.pdfFilesPages != 0 ? (
                      <>
                        <InfoIcon /> Total pdf page count <span>{props.file.pdfFilesPages}</span>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="bnt-container">
                  <Button className="btn-next" onClick={() => nextClickHandler()} disabled={props.filesList.some((x) => x.isLoading || x.error)}>
                    {/*//disabled={props.filesList.some((x) => x.isLoading || x.error)}*/}
                    Next
                  </Button>
                </div>
              </>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps, matDispatchToProps)(UploadFile);
