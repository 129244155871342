import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { AppDispatch, RootState } from "../store/store";
import { connect } from "react-redux";
import { FilesList } from "../types/StandardFilesTranslateTypes";
import { encode } from "base64-arraybuffer";
import { fileActions, generateConvertFileAction, getFileState } from "../reducers/file.slice";
import { v4 as uuidv4 } from "uuid";
import { ConvertFileRequest } from "../connector/models/apiModels";
import { controlActions, generateShowErrorAction } from "../reducers/control.slice";
import { AddBox } from "@mui/icons-material";

// @ts-ignore fix this later
import { gtag } from "ga-gtag";

interface DragNDropProps {
  projectId: string;
  class: string;
  fileList: FilesList[];

  detectSourceLang?(text: string): void;

  onFileUpload?(data: ConvertFileRequest): void;

  setProjectId?(projectId: string): void;

  addFilesName?(names: FilesList[]): void;

  onShowFilesCountError?(show: boolean, action: string): void;

  onShowPdfMixingModal?(show: boolean): void;

  onShowFilesSizePopUp?(show: boolean, fileName: string, title: string, content: string): void;

  onShowEncryptPdfPopUp?(show: boolean, fileName: string, title: string, content: string): void;
}

export function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    onFileUpload: (data: ConvertFileRequest) => dispatch(generateConvertFileAction(data)),
    setProjectId: (projectId: string) => dispatch(fileActions.setProjectId(projectId)),
    addFilesName: (names: FilesList[]) => dispatch(fileActions.addFilesName(names)),
    onShowFilesCountError: (show: boolean, action: string) => dispatch(generateShowErrorAction({ show, action })),
    onShowPdfMixingModal: (show: boolean) => dispatch(controlActions.showPdfMixingWarningModal(show)),
    onShowFilesSizePopUp: (show: boolean, fileName: string, title: string, content: string) =>
      dispatch(
        controlActions.showFilesSizePopUp({
          show: show,
          fileName: fileName,
          title: title,
          content: content,
        })
      ),
    onShowEncryptPdfPopUp: (show: boolean, fileName: string, title: string, content: string) =>
      dispatch(
        controlActions.showFilesSizePopUp({
          show: show,
          fileName: fileName,
          title: title,
          content: content,
        })
      ),
  };
}

export function mapStateToProps(state: RootState) {
  return {
    projectId: getFileState(state).projectId,
    fileList: getFileState(state).filesListData,
  };
}

const DragNDrop: React.FC<DragNDropProps> = (props: DragNDropProps) => {
  const maxSize = 31457280;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const filesList: FilesList[] = [];
    if (acceptedFiles.length > 10 && sessionStorage.getItem("false")) {
      props.onShowFilesCountError!(true, "countError");
      return;
    }
    acceptedFiles.forEach((x) => {
      const m: FilesList = {
        fileName: x.name,
        isLoading: true,
        id: uuidv4(),
        error: false,
        warning: false,
        errorMsg: {
          errorType: 0,
          content: null,
          color: null,
          fileName: null,
        },
        progress: 2,
      };
      filesList.push(m);
    });

    props.addFilesName!(filesList);
    if (props.projectId == "") {
      props.setProjectId!(uuidv4());
    }

    const pdfFilesCount = acceptedFiles.filter((x) => x.type.toLowerCase() == "application/pdf");
    if (pdfFilesCount.length > 0 && pdfFilesCount.length != acceptedFiles.length) {
      props.onShowPdfMixingModal?.(true);
    }

    /*Send google tag data only on prod */

    if (process.env.NODE_ENV == "production") {
      if (acceptedFiles.some((x) => x.name.includes(".pdf"))) {
        gtag("event", "share", { method: "User upload pdf file", content_type: "pdf" });
      } else {
        gtag("event", "share", { method: "User upload default file", content_type: "default" });
      }
    }
    let filesSize = 0;
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      //Check if pdf file is encrypt
      file.text().then((x) => {
        if (x.includes("Encrypt") || x.substring(x.lastIndexOf("<<"), x.lastIndexOf(">>")).includes("/Encrypt")) {
          props.onShowEncryptPdfPopUp!(true, file.name, "One or more files are encrypted", "Cannot process the file because it is locked or encrypted.");
          return;
        }
      });
      reader.onabort = () => props.onShowFilesSizePopUp!(true, file.name, "Your file(s) size is more than 100Mb. Please upload them separately.", "Can't process file because of it size ");
      reader.onerror = () => "file reading has failed";
      reader.readAsArrayBuffer(file);
      filesSize += file.size;
      if (filesSize < maxSize) {
        reader.onload = () => {
          const base64 = encode(reader.result as ArrayBuffer);
          props.onFileUpload!({
            name: file.name,
            base64String: base64,
            projectId: "",
            validateFilter: true,
            fileSource: 2,
            pdfType: 1,
          });
        };
      } else {
        reader.abort();
      }
    });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <section>
      <div {...getRootProps({ className: props.class })}>
        <input {...getInputProps()} />
        {props.fileList.length != 0 ? (
          <div className={" row d-inline-flex align-items-center p-2"} style={{ width: "100%", height: "100%", display: "flex" }}>
            <div className="col-lg-10 col-md-6 col-sm-12 text-center text-lg-start">Upload more files</div>
            <div className=" col-lg-2 col-md-6 col-sm-12 text-center text-lg-start">
              <AddBox style={{ marginLeft: "28px", opacity: "0.7" }} />
            </div>
          </div>
        ) : (
          <div className="drag-n-drop-text text-center">
            Select Files to Upload <br /> <span>or Drag and Drop</span>
          </div>
        )}
      </div>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DragNDrop);
