import React from "react";
import { Navigate } from "react-router-dom";
import { OpenUrl } from "../services/sso";

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  outlet: JSX.Element;
};

export default function ProtectedRoute({ isAuthenticated, authenticationPath, outlet }: ProtectedRouteProps) {
  OpenUrl("https://api.textunited.com/jsoneditor/auth/login?ReturnUrl=" + process.env.REACT_APP_SSO_URL + "&checkOnly=Y");
  if (isAuthenticated) {
    return outlet;
  } else {
    return <Navigate to={{ pathname: authenticationPath }} />;
  }
}
