import React from "react";
import { ReactComponent as FooterLogo } from "../../assets/footer/footer-logo.svg";
import OpinionsCarousel from "./OpinionsCarousel";

const Footer = () => {
  return (
    <div className="footer mt-5">
      <div className="container container-xxl text-center mt-5">
        <div className="row">
          <div className="col-md-1 text-left" style={{ textAlign: "left" }}>
            <a href={"https://www.textunited.com/"}>
              <FooterLogo />
            </a>
            {/*<div className="text-right mt-3">*/}
            {/*  <div className="col-md-2"></div>*/}
            {/*  <div className="socialIcons">*/}
            {/*    <a href="https://www.facebook.com/TextUnited">*/}
            {/*      <FbLogo />*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*  <div className="socialIcons">*/}
            {/*    <a href="https://textunited.medium.com/">*/}
            {/*      <MLogo />*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*  <div className="socialIcons">*/}
            {/*    <a href="https://at.linkedin.com/company/textunited">*/}
            {/*      <LinkedinLogo />*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*  <div className="socialIcons">*/}
            {/*    <a href="https://twitter.com/textunited">*/}
            {/*      <Twitter />*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <div className="col-md-11">
            <div className="row">
              <div className="col-md-9">
                <OpinionsCarousel />
              </div>
              <div className="col-md-3">
                <div className="titleFooter">Contact Us</div>
                <div className="row">
                  <div className="col-3" style={{ display: "inline-block" }}>
                    <img
                      style={{ textAlign: "left" }}
                      src="https://k6b8q4d6.rocketcdn.me/wp-content/uploads/2021/09/Vector-4.png"
                      alt="direction icon"
                      data-src="https://k6b8q4d6.rocketcdn.me/wp-content/uploads/2021/09/Vector-4.png"
                      decoding="async"
                      className=" ls-is-cached lazyloaded"
                    />
                    <noscript>
                      <img style={{ textAlign: "left" }} src="https://k6b8q4d6.rocketcdn.me/wp-content/uploads/2021/09/Vector-4.png" alt="direction icon" data-eio="l" />
                    </noscript>
                  </div>
                  <div className="col-9 font15" style={{ textAlign: "left", fontSize: "15px" }}>
                    Attemsgasse 7/D/20 1220 – Vienna Austria
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-3" style={{ display: "inline-block" }}>
                    <img
                      style={{ textAlign: "left" }}
                      src="https://k6b8q4d6.rocketcdn.me/wp-content/uploads/2021/11/envelop.png"
                      alt="envelop icon"
                      data-src="https://k6b8q4d6.rocketcdn.me/wp-content/uploads/2021/11/envelop.png"
                      decoding="async"
                      className=" lazyloaded"
                    />
                    <noscript>
                      <img style={{ textAlign: "left" }} src="https://k6b8q4d6.rocketcdn.me/wp-content/uploads/2021/11/envelop.png" alt="envelop icon" data-eio="l" />
                    </noscript>
                  </div>
                  <div className="col-9 font15" style={{ textAlign: "left", fontSize: "15px" }}>
                    servus@textunited.com
                  </div>
                </div>
                <div className="row" style={{ marginTop: "2rem", textAlign: "center" }}>
                  <a className="mx-5" href="https://www.textunited.com/ISO-17100-certification-enterprise-translation-services" target="_blank">
                    <img
                      src="https://k6b8q4d6.rocketcdn.me/wp-content/uploads/2022/05/Tuv_Certificate150x150.webp"
                      alt="TUV certficate"
                      data-src="https://k6b8q4d6.rocketcdn.me/wp-content/uploads/2022/05/Tuv_Certificate150x150.webp"
                      decoding="async"
                      className=" lazyloaded"
                    />
                    <noscript>
                      <img src="https://k6b8q4d6.rocketcdn.me/wp-content/uploads/2022/05/Tuv_Certificate150x150.webp" alt="TUV certficate" data-eio="l" />
                    </noscript>
                  </a>
                </div>
                {/*<div className="row mt-2">*/}
                {/*  <a href="https://www.capterra.com/p/151882/Text-United-Software/reviews/" target="_blank">*/}
                {/*    <div className="caterraBox">*/}
                {/*      <img*/}
                {/*        src="https://k6b8q4d6.rocketcdn.me/wp-content/uploads/2021/11/Group-28.svg"*/}
                {/*        alt="Capterra user review image"*/}
                {/*        style={{ width: "170px" }}*/}
                {/*        data-src="https://k6b8q4d6.rocketcdn.me/wp-content/uploads/2021/11/Group-28.svg"*/}
                {/*        decoding="async"*/}
                {/*        className=" lazyloaded"*/}
                {/*      />*/}
                {/*      <noscript>*/}
                {/*        <img src="https://k6b8q4d6.rocketcdn.me/wp-content/uploads/2021/11/Group-28.svg" alt="Capterra user review image" style={{ width: "170px" }} data-eio="l" />*/}
                {/*      </noscript>*/}
                {/*    </div>*/}
                {/*  </a>*/}
                {/*</div>*/}
                {/*<div className="row mt-2">*/}
                {/*  <a href="https://www.getapp.com/website-ecommerce-software/a/text-united/" target="_blank">*/}
                {/*    <div className="caterraBox">*/}
                {/*      <img*/}
                {/*        src="https://k6b8q4d6.rocketcdn.me/wp-content/uploads/2021/11/Group-29.svg"*/}
                {/*        alt="GetApp user review image"*/}
                {/*        style={{ width: "170px" }}*/}
                {/*        data-src="https://k6b8q4d6.rocketcdn.me/wp-content/uploads/2021/11/Group-29.svg"*/}
                {/*        decoding="async"*/}
                {/*        className=" lazyloaded"*/}
                {/*      />*/}
                {/*      <noscript>*/}
                {/*        <img src="https://k6b8q4d6.rocketcdn.me/wp-content/uploads/2021/11/Group-29.svg" alt="GetApp user review image" style={{ width: "170px" }} data-eio="l" />*/}
                {/*      </noscript>*/}
                {/*    </div>*/}
                {/*  </a>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyrights" style={{ background: "#ECEEF2", marginTop: "86px" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <p
                style={{
                  color: "#304254",
                  padding: "16px",
                  marginBottom: "unset",
                  opacity: "0.6",
                  fontSize: "15px",
                  float: "left",
                }}
              >
                2023 TextUnited GmbH /All Rights Reserved
              </p>
            </div>
            <div className="col-md-4">
              <p style={{ color: "#304254", padding: "16px", marginBottom: "unset" }}>
              <a className="bottomLinks" href="/FAQs-how-to-translate-PDF-files">
                  FAQ
                </a>
                <a className="bottomLinks" href="https://www.textunited.com/privacy-policy/">
                  Privacy Policy
                </a>
                <a className="bottomLinks" href="https://www.textunited.com/terms-conditions/">
                  {" "}
                  T&amp;Cs
                </a>
                <a className="bottomLinks" href="https://www.textunited.com/legal-notice/">
                  Legal Notice
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
