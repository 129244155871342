import { store, tu_conn } from "../store/store";
import { CostEstimationRequest } from "../connector/models/apiModels";
import { fileActions } from "../reducers/file.slice";

/*
These methods help to get different data which normally should be in store, but because of redirects no more is store
 */

/**
 * Get cost estimation for project
 * @param sourceLang - uploaded file source language
 * @param targetLang - translation languages
 * @param projectId - id of the project, generated by client side
 */
export async function getProjectCostEstimation() {
  const state = store.getState();

  const model: CostEstimationRequest = {
    projectId: state.file.projectId!,
    sourceLanguageCode: state.languages.sourceLanguage,
    targetLanguageCodes: state.languages.targetLanguages,
  };
  await tu_conn.getCostEstimation(model).then((r) => store.dispatch(fileActions.addCostEstimation(r.data)));
}

export function removeSessionStorageItems() {
  const storageKeys = Object.keys(sessionStorage);
  const keysToStay = ["token", "refresh_token", "anonymous"];
  const keysToDelete = ["words", "service", "isValid", "pdfPages", "cost", "sourceLang", "targetLang", "projectId", "step"];
  //const keysToDelete = storageKeys.filter((x) => !keysToStay.includes(x)); //filter((x) => !keysToStay.includes(x));
  const filteredArray = storageKeys.filter(item => keysToStay.includes(item));

  keysToDelete.forEach((x) => sessionStorage.removeItem(x));
}
