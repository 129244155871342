import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Stepper, Step, StepLabel, Typography } from "@mui/material";
import { AppDispatch, RootState } from "../store/store";
// import {selectControlState} from "../features/controlReducer";
// import {ControlTypes} from "../types/controlTypes";
import UploadFile from "./UploadFile";
// import languagePicker from "./LanguagePicker";
import ActiveIcon from "./svgIcons/ActiveIcon";
import NotActiveIcon from "./svgIcons/NotActiveIcon";
import { ControlTypes } from "../types/StandardFilesTranslateTypes";
import { getControlState } from "../reducers/control.slice";
import LanguagePicker from "../components/LanguagePicker";
import HowToTranslate from "./HowToTranslate";
import Order from "./Order";
import DownloadFile from "./DownloadFile";

interface stepperProps {
  control: ControlTypes;
}

const mapStateToProps = (state: RootState): stepperProps => {
  return {
    control: getControlState(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {};
};

const LinerStepper: React.FC<stepperProps> = (props: stepperProps) => {
  function getSteps(): Array<string> {
    return props.control.stepperSteps;
  }

  useEffect(() => {
    if (document.getElementById("Upload files") != null) {
      document!.getElementById("Upload files")!.className = "upload-step";
    }
  });

  const steps = getSteps();

  function getStepContent(step: number) {
    switch (props.control.step) {
      case 0:
        return <UploadFile />;
      case 1:
        return <LanguagePicker />;
      case 2:
        return <HowToTranslate />;
      case 3:
        return <Order />;
      case 4:
        return <DownloadFile />;
      default:
        return null;
    }
  }

  function stepClickHandler(e: React.MouseEvent<HTMLDivElement>) {
    if (e.currentTarget.textContent == "Upload files") {
      document.location.reload();
    }
  }

  return (
    <div className="linear-stepper">
      <Stepper activeStep={props.control.step} orientation="horizontal">
        {steps.map((step, index) => {
          if (props.control.step == 3 && index == 3) {
            return (
              <Step key={index}>
                <StepLabel StepIconComponent={ActiveIcon}>{step}</StepLabel>
              </Step>
            );
          } else {
            return (
              <Step key={index} onClick={(e) => stepClickHandler(e)} id={step}>
                <StepLabel StepIconComponent={props.control.step === index ? ActiveIcon : NotActiveIcon}>{step}</StepLabel>
              </Step>
            );
          }
        })}
      </Stepper>
      <Typography component={"span"}>{getStepContent(props.control.step)}</Typography>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LinerStepper);
