// noinspection ES6UnusedImports

import React, { useEffect, useRef } from "react";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { Button } from "react-bootstrap";
import { AppDispatch, RootState } from "../store/store";
import { controlActions, generateCheckUserEmail, generateNextOrderStepAction, getControlState } from "../reducers/control.slice";
import { connect } from "react-redux";
import { Address, Company, ControlTypes, UserRegisterInfo } from "../types/StandardFilesTranslateTypes";
import { FileState, getFileState } from "../reducers/file.slice";
import { FadeLoader } from "react-spinners";
import * as Yup from "yup";
import LoadingScreen from "./LoadingScreen";
import ErrorModal from "./modals/ErrorModal";
import { FormikSelect } from "./FormikSelectField";
import { Country } from "../connector/models/apiModels";
import { getLanguagesState, LanguagesState } from "../reducers/languages.slice";
import { generateUserRegisterAction, getUserState, userActions } from "../reducers/user.slice";
import { Box, Chip, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";
import PasswordStrength from "./PasswordStrength";

interface FormikValues {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  companyName?: string;
  street?: string;
  zip?: string;
  city?: string;
  country?: number | null;
}

interface OrderProps {
  file: FileState;
  countries: Country[];
  userEmail: string;
  controls: ControlTypes;
  projectId: string;
  activeStep: number;
  languages: LanguagesState;
  service: string;
  pdfPages: number;
  words: number;

  onNextOrderStep?(step: number): void;

  onSubmitClick?(email: string): void;

  onSubmit?(data: UserRegisterInfo): void;

  onCompanyDataSubmit?(addressData: Address, companyData: Company): void;

  userRegisterAction?(): void;

  onNextOrderStepClick?(step: number): void;

  addExtraStep?(stepName: string): void;
}

const mapStateToProps = (state: RootState): OrderProps => {
  return {
    controls: getControlState(state),
    file: getFileState(state),
    countries: getLanguagesState(state).countries,
    userEmail: getUserState(state).email,
    projectId: getFileState(state).projectId,
    activeStep: getControlState(state).step,
    languages: getLanguagesState(state),
    service: getFileState(state).service,
    pdfPages: getFileState(state).pdfFilesPages,
    words: getFileState(state).totalWordCount,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onNextOrderStep: (step: number) => dispatch(controlActions.setOrderStep(step)),
    onSubmitClick: (email: string) => dispatch(generateCheckUserEmail({ email })),
    onSubmit: (data: UserRegisterInfo) => dispatch(userActions.addUserRegisterInfo(data)),
    onCompanyDataSubmit: (data: Address, data2: Company) =>
      dispatch(
        userActions.addCompanyRegisterInfo({
          address: data,
          company: data2,
        })
      ),
    userRegisterAction: () => dispatch(generateUserRegisterAction()),
    onNextOrderStepClick: (step: number) => dispatch(generateNextOrderStepAction({ step: step })),
    addExtraStep: (step: string) => dispatch(controlActions.addStepperStep(step)),
  };
};

const Order: React.FC<OrderProps> = (props: OrderProps) => {
  const regFirstStepSchema = Yup.object().shape({
    firstName: Yup.string().required("First name"),
    lastName: Yup.string().required("Last name"),
    email: Yup.string().email().required("Email"),
    //password: Yup.string().min(12, "Password").required("password is "),
  });
  const regSecondStepSchema = Yup.object().shape({
    city: Yup.string().required("City"),
    street: Yup.string().required("Street").max(30, "Max length 30 charters"),
    zipCode: Yup.string().required("Zip code"),
    country: Yup.number().min(1, "Country").nullable(true).required("Required"),
  });
  // const {values, submitForm } = useFormikContext();
  let password = "";
  //Password validation
  const passValidate = (values: string) => {
    let error = "";
    if (values.length < 12) {
      error += "Minimum 12 characters, ";
    }
    if (!new RegExp(/[0-9]/)[Symbol.match](values)) {
      error += "Password requires a number,";
    }
    if (!new RegExp(/[a-z]/)[Symbol.match](values)) {
      error += "At least one lowercase,";
    }
    if (!new RegExp(/[A-Z]/)[Symbol.match](values)) {
      error += "At least one uppercase,";
    }
    if (!new RegExp(/[^\w]/)[Symbol.match](values)) {
      error += "At least one special character";
    }
    const t = error.split(",");

    password = values;
    return error;
  };

  const email = useRef<any>(null);
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const selectRef = useRef<any>(null);

  const [personalMailDomainsFound, setpersonalMailDomainsFound] = React.useState(false);

  const loginUrl = "https://api.textunited.com/jsoneditor/auth/login?ReturnUrl=" +
      process.env.REACT_APP_SSO_URL +
      //"https://localhost:44326/login/" +
      `${encodeURIComponent("?")}step=${props.activeStep}${encodeURIComponent("&projectId=")}${props.projectId}` +
      `${encodeURIComponent("&")}targetLang=${props.languages.targetLanguages}` +
      `${encodeURIComponent("&")}sourceLang=${props.languages.sourceLanguage}` +
      `${encodeURIComponent("&")}service=${props.service}` + //${encodeURIComponent(props.projectId)}
      `${encodeURIComponent("&")}pages=${props.pdfPages}` +
      `${encodeURIComponent("&")}words=${props.words}`;

  useEffect(() => {
    props.addExtraStep!("Registration");
    if (email.current?.focus) email.current?.focus();
  }, [email]);

  const onSubmitUserInfoTriggered = (values: FormikValues) => {
    
    const data: UserRegisterInfo = {
      firstName: values.firstName as string,
      lastName: values.lastName as string,
      email: values.email as string,
      password: password as string,
    };
    props.onSubmit!(data);
    props.onNextOrderStep?.(3);
  };

  const onSubmitCompanyInfo = (values: FormikValues) => {
    
    const addressData: Address = {
      city: values.city as string,
      address1: values.street as string,
      zipCode: values.zip as string,
      countryId: values.country as number,
    };
    const companyData: Company = {
      name: values.companyName as string,
    };
    props.onCompanyDataSubmit!(addressData, companyData);
    props.userRegisterAction!();
  };

  //Email validation
  const EmailSchema = Yup.object().shape({
    email: Yup.string().email().required("Email"),
  });

  useEffect(() => {
    if (email.current?.focus) email.current?.focus();
  }, [email]);

  const onEmailSubmit = (e: FormikValues) => {
    props.onSubmitClick!(e.email!);
    props.onNextOrderStep!(2);
  };

  const onEmailBlur = (e:any) => {

    if(e.currentTarget !== undefined 
      && e.currentTarget.value !== undefined 
      && e.currentTarget.value !== null 
      && e.currentTarget.value.length > 3){

      //check the email domains "gmail", "yahoo", "ukr", "onet", "hotmail", "gmx", "outlook", "mail", "aol", "liberto"
      const domainsList = ["@gmail", "@yahoo", "@ukr", "@onet", "@hotmail", "@gmx", "@outlook", "@mail", "@aol", "@liberto"]

      const isFound = domainsList.findIndex(name => {return e.currentTarget.value.includes(name)});
     
      if (isFound>-1) {
        setpersonalMailDomainsFound(true);
      } else {
        setpersonalMailDomainsFound(false);
      }
    }
  }



  return (
    <>
      {props.file.isLoading ? (
        <LoadingScreen />
      ) : (
        <div className={`card order-container`}>
          <ErrorModal />
          <div>
            {props.controls.orderStep == 1 && (
              <>
                <h1>
                  <span>Register</span>
                </h1>
                <Formik
                  validationSchema={EmailSchema}
                  validateOnChange={false}
                  initialValues={{
                    email: "",
                  }}
                  onSubmit={(e: FormikValues) => {
                    onEmailSubmit(e);
                  }}
                  ha
                >
                  {(formik) => {
                    const { errors, touched, isValid } = formik;
                    return (
                      <Form style={{ fontFamily: "Heebo" }} placeholder={undefined}>
                        <div className="row gx-4  my-4 m-auto row-flex">
                          <div className="col-12 col-lg-10 col-xxl-6 m-auto">
                            <TextField
                              autoComplete="on"
                              sx={{ width: "90%" }}
                              error={formik.touched.email && Boolean(formik.errors.email)}
                              inputRef={email}
                              id="email2nd"
                              name="email"
                              label={formik.errors.email == undefined ? "Email" : formik.errors.email}
                              variant="outlined"
                              placeholder="Enter your email address"
                              onChange={formik.handleChange}
                              onBlur={onEmailBlur}
                              defaultValue={formik.values.email}
                            ></TextField>
                            <sup id="info">
                              <Tooltip title="We will send a translation to this email address.">
                                <IconButton>
                                  <Info style={{ color: "rgba(8, 68, 249, 0.71)" }} />
                                </IconButton>
                              </Tooltip>
                            </sup>

                            {/*<Field innerRef={email} id="email2nd" name="email" placeholder="Enter your email address"></Field>*/}
                          </div>
                        </div>

                        { personalMailDomainsFound && (
                        <div className="row gx-4  my-4 m-auto row-flex">
                          <div className="col-12 col-lg-10 col-xxl-6 m-auto">
                            <span style={{color:'#251CC9'}}>If you register using your business email address, we'll add 15 PDF pages to your account within 24 hours as a token of our appreciation!</span>
                          </div>
                        </div>
                        )}
                        <div className="row gx-4  my-4 m-auto row-flex">
                          <div className="col-12 col-lg-10 col-xxl-6 m-auto">
                            <span>
                              Already have an account? <a href={loginUrl}>Log in</a>
                            </span>
                          </div>
                        </div>
                        <div className="text-center mb-4 continue-btn">
                          <Button type={"submit"} className="banner-btn register-button">
                            Continue
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </>
            )}
            {!props.controls.isHasAccount && props.controls.orderStep != 1 && (
              <>
                {props.file.isLoading ? (
                  <div className="upload-loading-scrn">
                    <FadeLoader height={20} width={7} radius={10} margin={12} color={"#43B4E9"}></FadeLoader>
                    <span className="text-center">Preparing your file...</span>
                  </div>
                ) : (
                  <>
                    <div className={"row mx-4 gx-4 my-4 m-auto"}>
                      <div className="col-12 col-lg-5 mx-5 my-auto register-header">
                        <span className={""}>Register</span>
                      </div>
                      <div className="col-12 col-lg-5 my-auto text-end">{/*<span className={"log-in"}>Already have an account? <a href="https://www.textunited.com/my/login/">LogIn</a></span>*/}</div>
                    </div>
                    {/*<RegisterScreen />*/}
                    {props.controls.orderStep == 2 && (
                      <Formik
                        initialValues={{
                          firstName: "",
                          lastName: "",
                          email: props.userEmail,
                          password: "",
                        }}
                        isInitialValid={false}
                        onSubmit={(values) => onSubmitUserInfoTriggered(values)}
                        innerRef={formRef}
                        validateOnChange={false}
                        validationSchema={regFirstStepSchema}
                      >
                        {(formik) => {
                          const { errors, touched, isValid, handleBlur, handleChange, setValues, setFieldError } = formik;
                          //setShowControls(isValid);
                          return (
                            <Form className={` mx-5 animate__animated ${props.controls.animation}`}
                                  style={{ height: "100%" }} placeholder={undefined}>
                              <div className="row gx-4 my-4 m-auto ">
                                <div className="col-12 col-lg-5 m-auto">
                                  <TextField
                                    sx={{ width: "100%" }}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    id="firstName"
                                    name="firstName"
                                    label={formik.errors.firstName == undefined ? "First name" : formik.errors.firstName}
                                    defaultValue={formik.values.firstName}
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                  ></TextField>
                                </div>
                                <div className=" col-12 col-lg-5 m-auto">
                                  <TextField
                                    sx={{ width: "100%" }}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    id="lastName"
                                    name="lastName"
                                    label={formik.errors.lastName == undefined ? "Last name" : formik.errors.lastName}
                                    defaultValue={formik.values.lastName}
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                  ></TextField>
                                </div>
                              </div>
                              <div className="row row-flex gx-4 my-4 m-auto">
                                <div className="col-12 col-lg-11 m-auto">
                                  <TextField
                                      autoComplete="off"
                                    sx={{ width: "100%" }}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    id="email"
                                    name="email"
                                    defaultValue={props.userEmail}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    label={formik.errors.email == undefined ? "Email" : formik.errors.email}
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                  ></TextField>
                                </div>
                              </div>
                              <div className="row gx-4 my-4 m-auto">
                                <div className="col-12 col-lg-11 m-auto">
                                  <TextField
                                      autoComplete="new-password"
                                    sx={{ width: "100%" }}
                                    type="password"
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    id="password"
                                    name="password"
                                    label={"Password"}
                                    variant="outlined"
                                    defaultValue={password}
                                    onChange={(e: React.ChangeEvent<any>) => {
                                      setFieldError("password", passValidate(e.currentTarget.value));
                                      formik.handleChange(e);
                                    }}
                                  ></TextField>
                                  {errors.password && (
                                    <div className="password-error-msg col-12 col-lg-12 animate__animated animate__fadeInDown">
                                      {
                                        <ul>
                                          {errors.password.split(",").map((error) => {
                                            if (error.length > 2) {
                                              return (
                                                <li>
                                                  {error} <b>required</b>
                                                </li>
                                              );
                                            }
                                            return null;
                                          })}
                                        </ul>
                                      }
                                    </div>
                                  )}
                                  {password.length > 1 ? <PasswordStrength errors={errors.password!} /> : null}
                                  {/*<ErrorMessage name="password">{msg => <div className="password-error-msg col-12 col-lg-11 m-auto">{<ul>{msg.split(",").map((error) => (<li>{error}</li>))}</ul>}</div>}</ErrorMessage>*/}
                                </div>
                              </div>

                              <div className=" col-12 text-center col-lg-5 mt-5 m-auto register-form-btn">
                                <Button type={"submit"} className={"btn-next"} disabled={errors.password?.length !== 0}>
                                  Continue
                                </Button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    )}
                    {props.controls.orderStep == 3 && (
                      <Formik
                        initialValues={{
                          companyName: "",
                          street: "",
                          zipCode: "",
                          city: "",
                          country: null,
                        }}
                        onSubmit={(values) => onSubmitCompanyInfo(values)}
                        isInitialValid={false}
                        validationSchema={regSecondStepSchema}
                        validateOnChange={true}
                      >
                        {(formik) => {
                          const { errors, touched, isValid } = formik;
                          return (
                            <Form
                                className={` register-2nd-step-form mx-5 animate__animated ${props.controls.animation}`}
                                placeholder={undefined}>
                              <div className="row gx-4 my-4 m-auto ">
                                <div className="col-12 col-lg-8 m-auto">
                                  <TextField
                                    sx={{ width: "100%" }}
                                    error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                                    id="companyName"
                                    name="companyName"
                                    label={formik.errors.companyName == undefined ? "Company name" : formik.errors.companyName}
                                    variant="outlined"
                                    defaultValue={formik.values.companyName}
                                    onChange={formik.handleChange}
                                  ></TextField>
                                  {/*<Field id="companyName" name="companyName" placeholder="Company name"></Field>*/}
                                </div>
                              </div>
                              <div className="row gx-4 my-4 m-auto ">
                                <div className=" col-12 col-lg-8 m-auto">
                                  <TextField
                                    sx={{ width: "100%" }}
                                    error={formik.touched.city && Boolean(formik.errors.city)}
                                    id="city"
                                    name="city"
                                    label={formik.errors.city == undefined ? "City" : formik.errors.city}
                                    variant="outlined"
                                    defaultValue={formik.values.city}
                                    onChange={formik.handleChange}
                                  ></TextField>
                                  {/*<Field id="city" name="city" placeholder="City"></Field>*/}
                                  {/*<ErrorMessage name={"city"}>{(msg) => <div>{msg}</div>}</ErrorMessage>*/}
                                </div>
                              </div>
                              <div className="row gx-4 my-4 m-auto ">
                                <div className=" col-12 col-lg-8 m-auto">
                                  <TextField
                                    sx={{ width: "100%" }}
                                    error={formik.touched.street && Boolean(formik.errors.street)}
                                    id="street"
                                    name="street"
                                    label={formik.errors.street == undefined ? "Street" : formik.errors.street}
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    defaultValue={formik.values.street}
                                  ></TextField>
                                  {/*<Field id="street" name="street" placeholder="Street"></Field>*/}
                                  {/*<ErrorMessage name={"street"}>{(msg) => <div>{msg}</div>}</ErrorMessage>*/}
                                </div>
                              </div>
                              <div className="row gx-4 my-4 m-auto ">
                                <div className=" col-12 col-lg-8 m-auto">
                                  <TextField
                                    sx={{ width: "100%" }}
                                    error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                                    id="zipCode"
                                    name="zipCode"
                                    label={formik.errors.zipCode == undefined ? "Zip code" : formik.errors.zipCode}
                                    variant="outlined"
                                    defaultValue={formik.values.zipCode}
                                    onChange={formik.handleChange}
                                  ></TextField>
                                  {/*<Field id="zipCode" name="zipCode" placeholder="Zip code"></Field>*/}
                                  {/*<ErrorMessage name={"zipCode"}>{(msg) => <div>{msg}</div>}</ErrorMessage>*/}
                                </div>
                              </div>
                              <div className="row gx-4 my-4 m-auto ">
                                <div className=" col-12 col-lg-8 m-auto">
                                  <Field name="country" component={FormikSelect} options={props.countries} />
                                  {/*<Select ref={selectRef} className={"countries-select"} styles={customStyles} placeholder={"Country"} options={props.countries} getOptionLabel={(c) => c.countryName} onChange={(e) => formik.setFieldValue("country", e!.id)} />*/}
                                  <ErrorMessage name={"country"}>{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                                </div>
                              </div>
                              <div className="register-form-btn disabled={!isValid}">
                                <Button type={"submit"} className="btn-next register-button">
                                  Register
                                </Button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
