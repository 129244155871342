import React from "react";
import LinerStepper from "./LinerStepper";
import Banner from "./Banner";

interface HomeScreenProps {}

export default function HomeScreen() {
  return (
    <div className="container container-xxl">
      <div className="row">
        <div className="col-md-9">
          <div className="box">
            <LinerStepper />
          </div>
        </div>
        <div className="col-md-3">
          <div className="box-banner">
            <Banner />
          </div>
        </div>
      </div>
    </div>
  );
}