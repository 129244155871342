import React, { useState } from "react";
import { AppDispatch, RootState } from "../store/store";
import { connect } from "react-redux";
import { ControlTypes, FilesList, UploadedFile } from "../types/StandardFilesTranslateTypes";
// import {getUploadFileState} from "../reducers/file.slice";
import "animate.css";
import { ClipLoader } from "react-spinners";
import { fileActions, FileValues, generateUpdatePdfFileTypeAction, selectAllFile } from "../reducers/file.slice";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { teal } from "@mui/material/colors";
import { IconButton, Tooltip } from "@mui/material";
import { controlActions, getControlState } from "../reducers/control.slice";
import { ProgressBar } from "react-bootstrap";

interface FilesListRowProps {
  file: FilesList;
  document: UploadedFile[];
  filesCount: number;
  controls: ControlTypes;

  onSwitchChange?(fileId: string, pdfType: number): void;

  onFileDeleteClick?(values: FileValues): void;

  hideAllPopUps?(): void;
}

const mapStateToProps = (state: RootState) => {
  return {
    document: selectAllFile(state),
    controls: getControlState(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onFileDeleteClick: (values: FileValues) => dispatch(fileActions.deleteFileFromList(values)),
    onSwitchChange: (fileId: string, pdfType: number) =>
      dispatch(
        generateUpdatePdfFileTypeAction({
          filedId: fileId,
          pdfType: pdfType,
        })
      ),
    hideAllPopUps: () => dispatch(controlActions.hideAllPopUps()),
  };
};

const FilesListRow: React.FC<FilesListRowProps> = (props: FilesListRowProps) => {
  const fileExtension = props.document.length > 0 ? props.document.find((x) => x.fileName == props.file.fileName) : null;
  const fileValues: FileValues = {
    fileId: "",
    fileListId: "",
  };
  const [progress, setProgress] = React.useState(10);
  const switchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let type = !e.currentTarget.checked ? 1 : 2;
  };

  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const onDeleteButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    fileValues.fileId = e.currentTarget.value;
    fileValues.fileListId = props.file.id;
    props.hideAllPopUps!();
    props.onFileDeleteClick!(fileValues);

    setShow(true);
  };

  const onSwitchChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    let type = !e.currentTarget.checked ? 1 : 2;
    props.onSwitchChange?.(e.currentTarget.value, type);
  };
  const onToolTipClick = (e: React.MouseEvent<HTMLButtonElement>) => {};
  return (
    <div className={`card mb-1 my-2 file-list-container ${props.filesCount == 1 ? "mt-5" : "mt-2"} animate__animated animate__fadeInDown`} hidden={show}>
      <div className="row d-inline-flex align-items-center p-2">
        <div className="col-lg-4 col-md-6 col-sm-12 text-center text-lg-start">{props.file.fileName.length >  30 ? props.file.fileName.substring(0, 30) + "..." : props.file.fileName}</div>
        <div className=" col-lg-3 col-md-4 col-sm-12 pdf-switch">
          <span className="text-center">{props.file.isLoading && (props.file.progress == undefined || props.file.progress == 100) ? "Processing" : null}</span>
          {/*{fileExtension?.fileExtension.toLowerCase() == ".pdf" ? (*/}
          {/*  <>*/}
          {/*    <Tooltip*/}
          {/*      style={{ color: "#0d6efd" }}*/}
          {/*      disableFocusListener*/}
          {/*      enterTouchDelay={0}*/}
          {/*      title={"These PDF files are created using software such as Microsoft® Word®, Excel® or via the “print”" + " function within a software application. They consist of text and images."}*/}
          {/*    >*/}
          {/*      <InfoOutlined />*/}
          {/*    </Tooltip>*/}
          {/*    <span>True PDF</span>*/}
          {/*    /!*<Switch defaultChecked={true} value={fileExtension?.fileId} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSwitchChangeHandle(e)} />*!/*/}
          {/*    <span>Image PDF</span>*/}
          {/*    <Tooltip*/}
          {/*      style={{ color: "#0d6efd" }}*/}
          {/*      disableFocusListener*/}
          {/*      enterTouchDelay={0}*/}
          {/*      title={"These PDF files are scanned hard copy documents, or converted a camera image, jpg, tiff or" + " screenshot into a" + " PDF. Such" + " content is “locked” in a snapshot-like image."}*/}
          {/*    >*/}
          {/*      <InfoOutlined />*/}
          {/*    </Tooltip>*/}
          {/*  </>*/}
          {/*) : null}*/}
        </div>
        <div className=" col-lg-2 col-md-6 col-sm-12 text-center text-lg-start" style={{ fontSize: "1rem" }}>
          {fileExtension?.wordcount ? <span>{fileExtension?.wordcount} words</span> : null}
        </div>
        <div className=" col-lg-1 col-md-6 col-sm-12 text-center text-lg-start">
          <div style={{ display: "flex", justifyContent: "center" }}>
            {props.file.isLoading ? (
              <>
                <ClipLoader size={15} />
              </>
            ) : props.file.error || props.file.warning ? (
              <IconButton value={fileExtension?.fileId} onClick={(e: React.MouseEvent<HTMLButtonElement>) => onToolTipClick(e)}>
                <Tooltip disableFocusListener enterTouchDelay={0} title={props.file.errorMsg.content}>
                  <ErrorOutlineIcon style={{ color: props.file.errorMsg.color as string }} />
                </Tooltip>
              </IconButton>
            ) : (
              <CheckCircleOutlinedIcon style={{ color: teal["A400"] }} />
            )}
          </div>
        </div>
        <div className="col-lg-1 col-md-6 col-sm-12 text-center text-lg-start">
          <IconButton value={fileExtension?.fileId} onClick={(e: React.MouseEvent<HTMLButtonElement>) => onDeleteButtonClick(e)}>
            <DeleteForeverIcon></DeleteForeverIcon>
          </IconButton>
        </div>
        <div>
          <ProgressBar animated={props.file.isLoading} variant="info" now={props.file.progress} />
        </div>
      </div>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(FilesListRow);
