import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, Fade } from "@mui/material";
import { AppDispatch, RootState } from "../../store/store";
import { connect } from "react-redux";
import { fileActions, FileState, getFileState } from "../../reducers/file.slice";
import { ControlTypes } from "../../types/StandardFilesTranslateTypes";
import { controlActions, getControlState } from "../../reducers/control.slice";

interface PdfMixingWarningModalProps {
  file: FileState;
  controls: ControlTypes;

  onOkayButtonClick?(show: boolean): void;

  setErrorOnAllPdf?(): void;
}

const mapStateToProps = (state: RootState) => {
  return {
    file: getFileState(state),
    controls: getControlState(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onOkayButtonClick: (show: boolean) => dispatch(controlActions.showPdfMixingWarningModal(show)),
    setErrorOnAllPdf: () => dispatch(fileActions.setErrorOnPdfFilesOnMixing()),
  };
};

const style = {};
const PdfMixingWarningModal: React.FC<PdfMixingWarningModalProps> = (props: PdfMixingWarningModalProps) => {
  const onOkClick = () => {
    props.onOkayButtonClick?.(false);
    //props.setErrorOnAllPdf?.();
  };
  return (
    <Modal open={props.controls.showPdfMixingWarning}>
      <Fade in={props.controls.showPdfMixingWarning}>
        <Box sx={style} className="word-allowance-modal">
          <div className="word-allowance-modal-content mx-4">
            <div className="word-allowance-modal-description">You must translate PDF file(s) separately. Delete files in other formats or delete PDF file(s) and continue with the remaining files.</div>
            <div className="word-allowance-modal-buttons" style={{ justifyContent: "center" }}>
              <Button variant={"outlined"} onClick={() => onOkClick()}>
                Okay
              </Button>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PdfMixingWarningModal);
