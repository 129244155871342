import React from 'react';
import { Button, IconButton, Switch, Tooltip, alpha, styled, CircularProgress, Chip } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import  { Check, Close, HelpOutline } from '@mui/icons-material'
import { green } from '@mui/material/colors';

import { AppDispatch } from "../store/store";
import { generateSubscriptionUpgradeBuyAction } from '../reducers/subscription.slice';
import { SubscribePlan } from '../connector/models/apiModels';
import { connect } from "react-redux";
  
  const AnnualSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: green[600],
      '&:hover': {
        backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: green[600],
    },
  }));

  const planDescription = [
      {
        index:100,  
        title: "\u00a0",
        price: "\u00a0",
        details: [
          {
              index:1,
              title:'',
              isHeading: true,
              toolTip:'',
              toolTipButtonTopPadding:'none'
          },
          {
              index:2,
              title:'Automated translation \n editable documents (30 file formats)',
              isHeading: false,
              toolTip:'',
              toolTipButtonTopPadding:'none'
          },
          {
              index:3,
              title:'Automated translation \n PDF files (scanned & native)',
              isHeading: false,
              toolTip:'',
              toolTipButtonTopPadding:'none'
          },
          {
              index:4,
              title:'Preserves the original \n formatting and layout',
              isHeading: false,
              toolTip:'PDF file conversion can result in some formatting loss, including data tables, multi-column layouts, and graphs with labels or legends.When possible, translate documents in their original form, for instance, a Word file',
              toolTipButtonTopPadding:'none'
          },
          {
              index:5,
              title:'Max file size',
              isHeading: false,
              toolTip:'',
              toolTipButtonTopPadding:'none'
          },
          {
              index:6,
              title:'PDF conversion guarantee',
              isHeading: false,
              toolTip:'We guarantee technical conversion of a PDF file within 24 hours in case automated conversion fails.The guarantee does not cover fixing layout issues related to the transformation of PDF files.',
              toolTipButtonTopPadding:'14px'
          }
        ]
        
      }
  ]

  const plans = [
    {
      index:110,
      title: "Free",
      price: "0",
      annualPrice:"0",
      priceTip:'',
      details: [
        {
            index:11,
            title:'\u00a0',
            isIcon: false,
            toolTip:'',
            paddingString:'10px 0px 10px 0px'
        },
        {
            index:12,
            title:'2,500 words',
            isIcon: false,
            toolTip:'',
            paddingString:'28px 0px 10px 0px'
        },
        {
            index:13,
            title:'5 pages',
            isIcon: false,
            toolTip:'',
            paddingString:'30px 0px 10px 0px'
        },
        {
            index:14,
            title:'yes',
            isIcon: true,
            toolTip:'',
            paddingString:'25px 0px 10px 0px'
        },
        {
            index:15,
            title:'20 MB',
            isIcon: false,
            toolTip:'',
            paddingString:'10px 0px 7px 0px'
        },
        {
            index:16,
            title:'no',
            isIcon: true,
            toolTip:'',
            paddingString:'2px 0px 7px 0px'
        },
        {
            index:17,
            title:'\u00a0',
            isIcon: false,
            toolTip:'',
            paddingString:'5px 0px 5px 0px'
        }
      ],      
      buttonText: "",
      subscriptionPlanId: "0"
    },
    {
      index:120,
      title: "Basic",
      subheader: "Most popular",
      price: "7 €",
      annualPrice:"70.56 €",
      priceTip:'VAT or sales tax is not included.',
      details: [
        {
            index:21,
            title:'\u00a0',
            isIcon: false,
            toolTip:'',
            paddingString:'10px 0px 10px 0px'
        },
        {
            index:22,
            title:'7,000 words',
            isIcon: false,
            toolTip:'',
            paddingString:'28px 0px 10px 0px'
        },
        {
            index:23,
            title:'15 pages',
            isIcon: false,
            toolTip:'',
            paddingString:'30px 0px 10px 0px'
        },
        {
            index:24,
            title:'yes',
            isIcon: true,
            toolTip:'',
            paddingString:'25px 0px 10px 0px'
        },
        {
            index:25,
            title:'30 MB',
            isIcon: false,
            toolTip:'',
            paddingString:'10px 0px 7px 0px'
        },
        {
            index:26,
            title:'yes',
            isIcon: true,
            toolTip:'',
            paddingString:'2px 0px 7px 0px'
        },
        {
            index:27,
            title:'\u00a0',
            isIcon: false,
            toolTip:'',
            paddingString:'5px 0px 5px 0px'
        }
      ],
      buttonText: "Subscribe",
      subscriptionPlanId: "1203"
    },
    {
      index:130,
      title: "Pro",
      price: "15 €",
      annualPrice:"151.20 €",
      priceTip:'VAT or sales tax is not included.',
      details: [
        {
            index:31,
            title:'\u00a0',
            isIcon: false,
            toolTip:'',
            paddingString:'10px 0px 10px 0px'
        },
        {
            index:32,
            title:'20,000 words',
            isIcon: false,
            toolTip:'',
            paddingString:'28px 0px 10px 0px'
        },
        {
            index:33,
            title:'25 pages',
            isIcon: false,
            toolTip:'',
            paddingString:'30px 0px 10px 0px'
        },
        {
            index:34,
            title:'yes',
            isIcon: true,
            toolTip:'',
            paddingString:'25px 0px 10px 0px'
        },
        {
            index:35,
            title:'40 MB',
            isIcon: false,
            toolTip:'',
            paddingString:'10px 0px 7px 0px'
        },
        {
            index:36,
            title:'yes',
            isIcon: true,
            toolTip:'',
            paddingString:'2px 0px 7px 0px'
        },
        {
            index:37,
            title:'\u00a0',
            isIcon: false,
            toolTip:'',
            paddingString:'5px 0px 5px 0px'
        }
      ],
      buttonText: "Subscribe",
      subscriptionPlanId: "1204"
    },
  ];

  //un comment this in case we need to use later https://www.textunited.com/my/signup/
  interface PricingScreenProps {
    onSubscriptionClick?(data: SubscribePlan) : void;
  }

  const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
      onSubscriptionClick: (data: SubscribePlan) => dispatch(generateSubscriptionUpgradeBuyAction(data)),
    };
  };
  

const PricingScreen : React.FC<PricingScreenProps> = (props: PricingScreenProps) => {

  const [annualChecked, setAnnualChecked] = React.useState(false);
  const [showLoadingButton, setShowLoadingButton] = React.useState(false);

  const handleAnnualChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnnualChecked(event.target.checked);
  };

  const handleEnterpriseSubscribe = (event: React.MouseEvent<HTMLButtonElement>) => {
    window.open('https://www.textunited.com/my/signup/','_blank');
  };

  const handlePlanSubscriptionBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {

    const upgradePlan : SubscribePlan = {
      subscriptionId: Number(e.currentTarget.id),
      priceYearly: annualChecked
    }
    setShowLoadingButton(true);
    props.onSubscriptionClick!(upgradePlan);
  }

  const alignPlanText = (text: string, key_index: number) : JSX.Element => {
     const wrap_key = 'kt_'+key_index+'_';
     const alignedText = text.split('\n').map((str,item_index) => <p key={wrap_key+item_index} style={{margin:'0px'}}>{str}</p>)
    return <div>{alignedText}</div>;
  }

  const showPrice = (monthly:string, annual: string) : string => {
    if (annualChecked){
      return annual
    }

    return monthly;
  }

  return (
        <div className="container container-xxl ps-5 pe-5 pt-2">
            <div className="row ">
                <div className="d-flex flex-row-reverse bd-highlight pb-3">
                  <div>
                    Save 16 % with annual billing   <AnnualSwitch checked={annualChecked} onChange={handleAnnualChange}   />
                  </div>
                </div>
            </div>
             <div className="row">
              <div className="col-md-4">
                <div className="d-flex flex-row-reverse bd-highlight">
                <div className="col-md-12">
                {planDescription.map((planInfo) => (
                <Card key={planInfo.index}  sx={{backgroundColor:'transparent', border:'0', boxShadow:'0', borderRadius:'0', transition:'none'}}>
                  <CardHeader
                    title={planInfo.title}
                    titleTypographyProps={{ align: "center", position: 'relative'}}
                    sx={{mt: 4}}
                    />
                  <CardContent >
                  
                      <Typography  variant="h4" color="text.primary" sx={{ textAlign: "center", position: 'relative'}}>
                        {planInfo.price}
                      </Typography>
                      {planInfo.details.map((row)=>(
                        <div key={row.index} className='ms-auto'>
                             {row.isHeading ?
                                (row.toolTip !== '') ?
                                  <Typography   variant="subtitle1"  color="text.primary" sx={{ textAlign: "right", position: 'relative', paddingTop:'4px'}}>
                                   {row.title}<Tooltip title={row.toolTip} sx={{fontSize:'1rem'}}>
                                      <IconButton sx={{marginTop:'-4px'}}>
                                        <HelpOutline />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                :
                                <Typography   variant="subtitle1"  color="text.primary" sx={{ textAlign: "right", position: 'relative', paddingTop:'4px',paddingRight:'40px'}}>
                                   {row.title}
                                </Typography>
                            :
                            (row.toolTip !== '') ?
                                <div  className="d-flex flex-row-reverse bd-highlight"  >
                                  <Tooltip title={row.toolTip} sx={{fontSize:'1rem'}}>
                                      <IconButton sx={{marginTop:'-4px', paddingTop:row.toolTipButtonTopPadding}}>
                                        <HelpOutline />
                                      </IconButton>
                                    </Tooltip>
                                    <div  style={{  paddingTop:'10px',paddingBottom:'5px',textAlignLast:'end' }}>
                                     {alignPlanText(row.title,row.index)}
                                  </div>                                    
                                  </div>
                              :
                              <div className="d-flex flex-row-reverse bd-highlight"  >
                                <div  style={{  paddingTop:'10px',paddingBottom:'5px',paddingRight:'40px',textAlignLast:'end' }}>
                                     {alignPlanText(row.title,row.index)}
                                  </div>
                              </div>}
                        </div>   
                      ))}   
                    
                    
                  </CardContent>
                  <CardActions >
                    &nbsp;
                  </CardActions>
                </Card>
                ))}
                </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row">
                 
             {plans.map((plan,index) => (
                <div  className="col-md-4" key={plan.index}>
              
                <Card>
                  <CardHeader
                    title={plan.title}
                    titleTypographyProps={{ align: "center", position: 'relative'}}
                    
                    />
                  <CardContent sx={{padding:'0px'}}>
                    
                     { (plan.priceTip !== '') ?
                     
                      <Typography component="h6"   variant="h6" color="text.primary" sx={{ textAlign: "center", position: 'relative'}}>
                        {showPrice(plan.price,plan.annualPrice)}<Tooltip title={plan.priceTip} sx={{fontSize:'1rem'}}>
                                      <IconButton sx={{marginTop:'-4px'}}>
                                        <HelpOutline />
                                      </IconButton>
                                    </Tooltip>
                      </Typography>
                      :
                      <Typography component="h6"   variant="h6" color="text.primary" sx={{ textAlign: "center", position: 'relative'}}>
                        {showPrice(plan.price,plan.annualPrice)}
                      </Typography>
                    
                    }
                      {
                        plan.details.map((row)=>(
                        
                          row.isIcon ?
                             (row.title === 'yes') ?
                                <Typography key={row.index} variant="subtitle1"   sx={{ textAlign: "center", position: 'relative' , padding: row.paddingString, color:'#2fe71f', lineHeight:'2.75'}}>
                                     <Check/>
                                </Typography>
                                :
                                <Typography key={row.index} variant="subtitle1"   sx={{ textAlign: "center", position: 'relative' , padding: row.paddingString, color:'#6c757d', lineHeight:'2.75'}}>
                                    <Close/>
                                </Typography>

                            :
                            <div key={row.index} className="d-flex" style={{justifyContent:'center'}} >
                                <div style={{padding: row.paddingString }}>
                                {row.title}
                                  </div>
                                  </div>
                              
                        ))

                      }   
                    
                      
                  </CardContent>
                  <CardActions >
                    {
                    (plan.buttonText !== '')?
                    <Button  fullWidth variant='contained' size='small'
                    id={plan.subscriptionPlanId}
                             disabled={showLoadingButton}
                    sx={{
                      backgroundColor: '#251cc9',
                      color: '#fff',
                      ':hover': {
                        bgcolor: '#7e39fb',
                        color: '#fff',
                      }
        }} onClick={(e: React.MouseEvent<HTMLButtonElement>) => handlePlanSubscriptionBtnClick(e) }>
                      {plan.buttonText}
                    </Button>
                    : // '\u00a0'
                    <p style={{margin:'5px 0px'}}>&nbsp;</p>
                    }
                  </CardActions>
                </Card>
              
              </div>
            ))}  
          
              </div>
              </div>
            </div>
        </div>
      );
}

export default connect( null,mapDispatchToProps)(PricingScreen);

 