// @ts-ignore fix this later
import { gtag } from "ga-gtag";

export function purchaseEvent(stripeId: string, amount: number, title: string, category: string) {
  gtag("event", "purchase", {
    transaction_id: stripeId,
    value: amount,
    tax: 0,
    shipping: 0,
    currency: "EUR",
    items: [
      {
        item_id: title.trim() + "-1", //Change to translation type
        item_name: title,
        affiliation: "TUFT",
        discount: 0,
        item_category: category,
        item_list_id: "",
        item_list_name: "",
        price: amount,
        quantity: 1,
      },
    ],
  });
}
