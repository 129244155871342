import { ITUConnector } from "./ITUConnector";
import axios, { AxiosProgressEvent, AxiosResponse } from "axios";
import { LanguagesEntity } from "../reducers/languages.slice";
import { axiosClient } from "../services/axiosConfig";
import {
  ClientSubscription,
  CompanyPreferencesResponse,
  Contact,
  ConvertFileRequest,
  ConvertFileResponse,
  CostEstimationRequest,
  CostEstimationResponse,
  Country,
  CreateProjectResponse,
  GetUserInfoResponse,
  MachineTranslationResponse,
  MailRequest,
  PrepareStripeCheckoutRequest,
  PrepareStripeCheckoutResponse,
  Project,
  ProjectRoot, RegisterNewUserMail,
  RegisterUserRequest,
  RegisterUserResponse, SendGridContact,
  SetTaxNumberRequest,
  StripeBuySuccessfullyResponse,
  SubscriptionBuyRequest,
  SubscriptionBuyResponse,
  Token,
  UserLocation,
  ValidateFilesRequest,
  ValidateFilesResponse
} from "./models/apiModels";
import { DetectedLanguage, User } from "../types/StandardFilesTranslateTypes";
import { store } from "../store/store";
import { fileActions } from "../reducers/file.slice";

const base_url = "https://api.textunited.com/";
let stripe = "";
export const controller = new AbortController();

export class TUConnector implements ITUConnector {
  async getLanguages(): Promise<any> {
    const languages = await axiosClient.get<LanguagesEntity[]>(base_url + "OpenEditor/languages");
    return languages.data;
  }

  async getAnonymousToken(): Promise<any> {
    const data = axios.post(base_url + "authentication/api/AnonymousToken");
    return data;
  }

  //TODO: change pdf type
  async convertFile(model: ConvertFileRequest): Promise<AxiosResponse<ConvertFileResponse>> {
    const config = {
      onUploadProgress: function (progressEvent: AxiosProgressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total!);
        store.dispatch(fileActions.setFileProgress({ progress: percentCompleted, fileName: model.name }));
      },
    };
    const convertedFile = await axiosClient.post<ConvertFileResponse>(
      base_url + "docprocessor/Convert",
      {
        projectId: model.projectId,
        base64File: model.base64String,
        filename: model.name,
        validateFilter: true,
        fileSource: 2,
        pdfType: model.pdfType,
      },
      config
    );
    return convertedFile;
  }

  async languageDetect(text: string): Promise<AxiosResponse<DetectedLanguage>> {
    const data = await axiosClient.post("OpenEditor/LanguageDetection", {
      text: text,
    });
    return data;
  }

  async getCostEstimation(model: CostEstimationRequest): Promise<AxiosResponse<CostEstimationResponse>> {
    const costEstimation = await axiosClient.post<CostEstimationResponse>(base_url + "docprocessor/api/costEstimation", {
      projectId: model.projectId,
      sourceLanguageCode: model.sourceLanguageCode,
      targetLanguageCodes: model.targetLanguageCodes,
    });
    return costEstimation;
  }

  async checkIfUserGetAccount(email: string): Promise<AxiosResponse<boolean>> {
    const isExist = await axiosClient.get<boolean>(base_url + "users/UserExists?email=" + email);
    return isExist;
  }

  async getToken(): Promise<AxiosResponse<Token>> {
    const token = await axiosClient.post<Token>(base_url + "authentication/token");
    return token;
  }

  async getUserLocation(): Promise<AxiosResponse<UserLocation>> {
    const location = await axios.get<UserLocation>("https://geolocation-db.com/json/");
    return location;
  }

  async prepareStripeCheckout(model: PrepareStripeCheckoutRequest): Promise<AxiosResponse<PrepareStripeCheckoutResponse>> {
    const data = await axiosClient.post<PrepareStripeCheckoutResponse>(base_url + "docprocessor/api/ProjectBuy", {
      projectId: model.projectId,
      costEstimationOption: model.costEstimationOption,
      successUrl: model.successUrl,
      cancelUrl: model.cancelUrl,
      title: model.title,
      type: model.type,
    });
    return data;
  }
  
  async getCountries(): Promise<AxiosResponse<Country[]>> {
    const countries = axios.get<Country[]>("https://api.textunited.com/Integrations/Countries");
    return countries;
  }

  async registerNewUser(model: RegisterUserRequest): Promise<AxiosResponse<RegisterUserResponse>> {
    const user = axiosClient.post(base_url + "users/Users", model);
    return user;
  }

  async checkFileSupport(model: ValidateFilesRequest): Promise<AxiosResponse<ValidateFilesResponse>> {
    new Promise((resolve) => setTimeout(resolve, 2000));
    const result = axiosClient.post(base_url + "docprocessor/api/ValidateFile", model);
    return result;
  }

  async checkIfStripeBuySuccessfully(projectId: string): Promise<AxiosResponse<StripeBuySuccessfullyResponse>> {
    const result = axiosClient.post(base_url + "docprocessor/api/ProjectBuySuccessfully", { projectId });
    return result;
  }

  async sendEmail(model: MailRequest): Promise<AxiosResponse<string>> {
    const result = axiosClient.post(base_url + "Communication/api/Mail/generalPuprose", model);
    return result;
  }

  async machineTranslation(projectId: string, connectionId: string): Promise<AxiosResponse<any>> {
    const result = axiosClient.post(base_url + "docprocessor/api/MachineTranslation", { projectId }, { headers: { ["signal-r-connection-id"]: connectionId } });
    return result;
  }

  machineTranslationDownload(projectId: string): Promise<AxiosResponse<MachineTranslationResponse>> {
    const result = axiosClient.get(base_url + "docprocessor/api/MachineTranslationDownload/" + projectId, {
      responseType: "arraybuffer",
      decompress: true,
    });
    return result;
  }

  machineTranslationGetStatus(projectId: string): Promise<AxiosResponse<MachineTranslationResponse>> {
    const result = axiosClient.get(base_url + "docprocessor/api/MachineTranslationStatus/" + projectId);
    return result;
  }

  createProject(projectId: string, serviceOption: string, offerRequired: boolean): Promise<AxiosResponse<CreateProjectResponse>> {
    const result = axiosClient.post(base_url + "projectAutomation/api/ProjectByDocprocessor", {
      docProcessorProjectId: projectId,
    });
    return result;
  }

  async setOfferRequested(projectId: string, requested: boolean): Promise<AxiosResponse<any>> {
    const result = axiosClient.post(base_url + `docprocessor/Projects/${projectId}/SetOfferRequested/${requested}`);
    return  result;
  }

  checkUserWordsAllowance(): Promise<AxiosResponse<ClientSubscription>> {
    const result = axiosClient.get(base_url + "subscription/clientSubscriptionAllowances");
    return result;
  }

  async setCompanyId(projectId: string): Promise<AxiosResponse<string>> {
    const result = axiosClient.post(base_url + `docprocessor/Projects/${projectId}/setCompanyId`);
    return result;
  }

  async setCostEstimationOption(projectId: string, option: string): Promise<AxiosResponse<string>> {
    const result = axiosClient.post(base_url + `docprocessor/Projects/${projectId}/SetCostEstimationOption/${option}`);
    return result;
  }

  async userLogOut(): Promise<void> {
    document.location.href = "https://www.textunited.com/my/user/logout/?url=" + process.env["REACT_APP_SSO_URL"];
    //document.location.href = "https://localhost:44326/user/logout/?url=" + process.env["REACT_APP_SSO_URL"];
  }

  async userLogIn(accessToken: string, refreshToken: string, email: string): Promise<void> {
    const url = `https://www.textunited.com/my/portal/LoginFromOuter/?accessToken=${accessToken}&refreshToken=${refreshToken}&email=${email}`; //`https://localhost:44326/portal/loginfromouter/?accessToken=${accessToken}&refreshToken=${refreshToken}&email=${email}`;
    // `https://textunited.com/my/portal/LoginFromOuter/?accessToken=${accessToken}&refreshToken=${refreshToken}&email=${email}`;
    document.location.replace(url);
  }

  async updatePdfFileType(projectId: string, fileId: string, pdfType: number): Promise<AxiosResponse<ConvertFileResponse>> {
    const convertedFile = await axiosClient.put<ConvertFileResponse>(base_url + "docprocessor/Convert", {
      projectId: projectId,
      fileId: fileId,
      pdfType: pdfType,
    });
    return convertedFile;
  }

  getUserData(): Promise<AxiosResponse<GetUserInfoResponse>> {
    return axiosClient.get(base_url + "users/users/me");
  }

  getProjectList(): Promise<AxiosResponse<Project[]>> {
    return axiosClient.get(base_url + "docprocessor/api/projects");
  }

  subscriptionBuy(request: SubscriptionBuyRequest): Promise<AxiosResponse<SubscriptionBuyResponse>> {
    return axiosClient.put(base_url + "subscription/api/StripeUserSubscription", request);
  }

  getCompanyPreferences(): Promise<AxiosResponse<CompanyPreferencesResponse>> {
    return axiosClient.get(base_url + "users/api/CompanyPreferences");
  }

  updateUserData(request: User) {
    return axiosClient.put(base_url + "users/users/me?updateComapnySettings=true", request);
  }

  setTaxNumber(request: SetTaxNumberRequest) {
    return axiosClient.post(base_url + "users/CompanyPreferences/SetTaxNumber", request);
  }

  subscriptionClose() {
    return axiosClient.post(base_url + "subscription/api/ClientSubscriptionCancel");
  }

  createContact(request: Contact): Promise<AxiosResponse<Contact>> {
    return axiosClient.post(base_url + "events/api/Events", request);
  }

  createSendGridContact(request: SendGridContact): Promise<AxiosResponse<any>> {
    return axiosClient.post(base_url + "events/api/Events", request);
  }

  getProjectInfo(projectId: string): Promise<AxiosResponse<ProjectRoot>> {
    return axiosClient.get(base_url + `docprocessor/api/Projects/${projectId}`);
  }

  sendNewUserRegistration(request: RegisterNewUserMail): Promise<AxiosResponse> {
    return  axiosClient.post(base_url + 'Communication/Mail/newTuftUserRegistration', request)
  }
}
