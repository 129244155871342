import React from "react";
import { FadeLoader } from "react-spinners";
import { RootState } from "../store/store";
import { getFileState } from "../reducers/file.slice";
import { connect } from "react-redux";
import { getControlState } from "../reducers/control.slice";

interface LoadingScreenProps {
  show: boolean;
  message: string;
}

const mapStateToProps = (state: RootState) => {
  return {
    show: getFileState(state).isLoading,
    message: getControlState(state).loadingMessage,
  };
};

const LoadingScreen: React.FC<LoadingScreenProps> = (props: LoadingScreenProps) => {
  return (
    <div className={"card"} style={{ marginTop: "5rem" }}>
      <div className="upload-loading-scrn">
        <FadeLoader height={20} width={7} radius={10} margin={12} color={"#43B4E9"}></FadeLoader>
        <span className="text-center">{props.message == "" ? "Loading..." : props.message}</span>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(LoadingScreen);
