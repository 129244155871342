import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { ApiMiddleware } from "../middleware/middleware";
import { TUConnector } from "../connector/TUConnector";
import { CONTROL_FEATURE_KEY, controlReducer } from "../reducers/control.slice";
import { FILE_FEATURE_KEY, fileReducer } from "../reducers/file.slice";
import { LANGUAGES_FEATURE_KEY, languagesReducer } from "../reducers/languages.slice";
import { USER_FEATURE_KEY, userReducer } from "../reducers/user.slice";
import { SUBSCRIPTION_FEATURE_KEY, subscriptionReducer } from "../reducers/subscription.slice";
import { PROJECTS_FEATURE_KEY, projectsReducer } from "../reducers/projects.slice";
import * as Sentry from "@sentry/react";


export const tu_conn: TUConnector = (window as any).tu !== undefined ? (window as any).tu : new TUConnector();

const apiMiddleware = new ApiMiddleware(tu_conn);
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});
export const store = configureStore({
  reducer: {
    [CONTROL_FEATURE_KEY]: controlReducer,
    [FILE_FEATURE_KEY]: fileReducer,
    [LANGUAGES_FEATURE_KEY]: languagesReducer,
    [USER_FEATURE_KEY]: userReducer,
    [SUBSCRIPTION_FEATURE_KEY]: subscriptionReducer,
    [PROJECTS_FEATURE_KEY]: projectsReducer,
  },
  // Additional middleware can be passed to this array
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiMiddleware.middleware),
  devTools: true,

  // Optional Redux store enhancers
  enhancers: [sentryReduxEnhancer],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
