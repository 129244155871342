import React from "react";
import {ReactComponent as Info} from "../../assets/uploadFile/info-icon.svg";
import SvgIcon from "@mui/material/SvgIcon";

const InfoIcon = () => (
  <SvgIcon>
    <Info />
  </SvgIcon>
);

export default InfoIcon;