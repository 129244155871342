import { HUMAN_TRANSLATION_AND_REVISION, MACHINE_TRANSLATION_AND_REVIEW, MACHINE_TRANSLATION_NAME } from "../editorConstants";

export function convertCostEstimationToString(option: string): string {
  let serviceName = "";
  switch (parseInt(option)) {
    case 1:
      serviceName = MACHINE_TRANSLATION_NAME;
      break;
    case 2:
      serviceName = MACHINE_TRANSLATION_AND_REVIEW;
      break;
    case 3:
      serviceName = HUMAN_TRANSLATION_AND_REVISION;
  }
  return serviceName;
}
