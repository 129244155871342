import React, { ChangeEvent, FC, useEffect } from "react";
import ProjectRow from "./ProjectRow";
import { generateFetchOrdersListAction, ProjectsEntity, selectAllProjects } from "../reducers/projects.slice";
import { AppDispatch, RootState } from "../store/store";
import { connect } from "react-redux";
import Enumerable from "linq";
import { LanguagesEntity, selectAllLanguages } from "../reducers/languages.slice";
import { Pagination, Tooltip, useMediaQuery } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

interface ProjectsListProps {
  projects: ProjectsEntity[];
  languages: LanguagesEntity[];

  onFetchProjects(): void;
}

const mapStateToProps = (state: RootState) => {
  return {
    projects: selectAllProjects(state),
    languages: selectAllLanguages(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onFetchProjects: () => dispatch(generateFetchOrdersListAction()),
  };
};
const ProjectList: FC<ProjectsListProps> = (props: ProjectsListProps) => {
  const matches = useMediaQuery("(-webkit-min-device-pixel-ratio: 1.25)");

  useEffect(() => {
    props.onFetchProjects();
  });

  let take = 10;
  let skip = 0;
  let page = 0;
  matches ? (take = 5) : (take = 10);
  let projects = Enumerable.from(props.projects)
    .where((y) => y.costEstimationRequest != null && y.sourceLanguageCode != "")
    .skip(skip)
    .take(take)
    .toArray();

  const handlePaginationChange = (e: ChangeEvent<unknown>, p: number) => {
    if (page > p) {
      take -= 10;
      skip -= 10;
      page -= p;
    } else {
      page += p;
      take += 10;
      skip += 10;
    }
    projects = Enumerable.from(props.projects)
      .where((y) => y.costEstimationRequest != null && y.serviceName != "Machine Translation" && y.sourceLanguageCode != "")
      .skip(skip)
      .take(take)
      .toArray();

    refreshProjectsList();
  };

  const refreshProjectsList = () => {
    return projects
      .filter((x) => x.costEstimationRequest != null)
      .map((x) => (
        <ProjectRow
          projectId={x.projectId}
          serviceName={x.serviceName}
          languagesList={x.targetLanguageCodes}
          key={x.projectId}
          sourceLang={x.sourceLanguageCode}
          date={x.created.toString()}
          price={x.servicePrice == 0 ? "Free" : x.servicePrice.toString()}
        />
      ));
  };

  return (
    <div className="p-6 max-w-screen-2xl h-screen mt-5 mx-auto rounded-xl shadow-lg items-center space-x-4 xl:h-screen xl:scale-80 projects-list">
      <div className="flex flex-row justify-between ml-4">
        <div className=" flex flex-row justify-between ml-4">
          <div className="flex flex-row align-baseline items-center">
            <Link to={"/"}>
              <ArrowBackIosIcon color="info" />
            </Link>
            <span className="font-bold 2xl:text-5xl xl:text-3xl">
              Orders <span className="text-blue-800">list</span>
            </span>{" "}
            <Tooltip title={"This list includes only project enquiries and ordered translations which involve human translators." + "\nAutomated translations are not kept as projects."}>
              <HelpOutlineIcon className={"ml-2"} color={"info"} />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="flex flex-row space-x-4 text-sm font-bold leading-6 mr-2 mx-5 mt-2 2xl:text-xl xl:text-lg orders-header">
        <div className="ml-5 basis-1/3 h-14 rounded-lg flex items-center justify-start">Order Id</div>
        <div className="basis-1/3 h-14 rounded-lg flex items-center justify-start ">Service</div>
        <div className="basis-1/6 h-14 rounded-lg flex items-center justify-start ">Languages</div>
        <div className="basis-1/12 h-14 rounded-lg flex items-center justify-start ">Created</div>
        <div className="basis-1/12 h-14 rounded-lg flex items-center justify-start ">Cost</div>
      </div>

      <div>
        {projects.length == 0 ? (
          <div className="text-center mt-5">
            <span className="text-xl">
              This list includes only project enquiries and ordered translations which involve human translators <br />
              Automated translations are not kept as projects
            </span>
          </div>
        ) : (
          <>
            {projects
              .filter((x) => x.costEstimationRequest != null)
              .map((x) => (
                <ProjectRow
                  key={x.projectId}
                  projectId={x.projectId}
                  serviceName={x.serviceName}
                  sourceLang={x.sourceLanguageCode}
                  languagesList={x.targetLanguageCodes}
                  date={x.created.toString()}
                  price={x.servicePrice == 0 ? "" : (Math.round(x.servicePrice) / 100).toString()}
                />
              ))}
            <div className="flex flex-row justify-center mt-3">
              <Pagination className="" count={Math.round(props.projects.length / 10)} onChange={(e, page) => handlePaginationChange(e, page)} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
