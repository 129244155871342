import React, { useEffect } from "react";
import { Box, Button, CircularProgress, CircularProgressProps } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { SubscriptionType } from "../types/StandardFilesTranslateTypes";
import { AppDispatch, RootState } from "../store/store";
import { generateGetSubscriptionDataAction, generateSubscriptionBuyAction, generateSubscriptionCancelAction, getSubscriptionState } from "../reducers/subscription.slice";
import { connect } from "react-redux";
import { ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LoadingScreen from "./LoadingScreen";
import SubscriptionCancelModal from "./modals/SubscriptionCancelModal";
import { controlActions } from "../reducers/control.slice";

import AbcIcon from '@mui/icons-material/Abc';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';

interface SubscriptionProps {
  open: boolean;

  subscription: SubscriptionType;

  onSubscriptionClick?(): void;

  fetchSubscriptionData(): void;

  onSubscriptionCancelClick(): void;
}

const mapStateToProps = (state: RootState) => {
 return {
    subscription: getSubscriptionState(state),
    open: state.control.showSubscriptionCancelModal,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onSubscriptionClick: () => dispatch(generateSubscriptionBuyAction()),
    fetchSubscriptionData: () => dispatch(generateGetSubscriptionDataAction()),
    onSubscriptionCancelClick: () => dispatch(controlActions.showSubscriptionCancelModal(true)),
  };
};

const Subscription: React.FC<SubscriptionProps> = (props: SubscriptionProps) => {
  useEffect(() => {
    if (!props.subscription.dataLoaded) {
      props.fetchSubscriptionData();
    }
  });

  const handleSubscriptionBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.innerText == "CANCEL") {
      props.onSubscriptionCancelClick();
    }

    //OLD Hard-Coded plan subsciption way - CAN be Removed after Final Consent
    if (e.currentTarget.innerText == "SUBSCRIBE") {
      props.onSubscriptionClick!();
    }

    if (e.currentTarget.innerText == "CHANGE SUBSCRIPTION") {
      window.location.href = 'Pricing';
    }

  };

  //internal functions

  const getSubscriptionName = (planId: number,isYearly: boolean) : any => {

    let _planName = "Free"

    switch(planId) {
      case  1203 : // Basic
        _planName = "Basic"
        break;
      case  1204 : // Pro
        _planName = "Pro"
        break;

    }

    if(isYearly) {
      _planName += " Annual"
    } else {
      _planName += " Monthly"
    }

    return _planName;
    
  }

  return (
    <div className="p-6 max-w-screen-2xl h-full mt-5 mx-auto rounded-xl shadow-lg items-center space-x-4 subscription xl:h-full xl:scale-80 2xl:scale-100">
      {props.subscription.dataLoaded ? (
        <>
        <SubscriptionCancelModal/>
          <div className="flex flex-row align-baseline items-center ml-5">
            <Link to={"/"}>
              <ArrowBackIosIcon color="info" />
            </Link>
            <span className={`font-bold 2xl:text-4xl xl:text-3xl`}>Subscription</span>
          </div>
          <div className="flex flex-row mt-12 items-center">
            <div className=" flex flex-row basis-9/12 rounded-lg  justify-between items-center">
                <div className="flex flex-row justify-between items-center w-full">
                  <span className="text-2xl">
                    Subscription: <span className="font-bold">{getSubscriptionName(props.subscription.subData.subscriptionPlanId, props.subscription.subData.priceYearly)}</span>
                  </span>
                  <span className="text-2xl">
                    {!props.subscription.subData.canceled && props.subscription.subData.subscriptionPlanId === 1202 ? (null): (
                      <span>{props.subscription.subData.canceled ? "Valid to" : "Renew at"}: <span className="font-bold">{new Date(props.subscription!.subData!.validTo!).toLocaleDateString()}</span></span>
                    )}
                    {/* {props.subscription.subData.canceled ? "Valid to" : "Renew at"}: <span className="font-bold">{new Date(props.subscription!.subData!.validTo!).toLocaleDateString()}</span> */}
                  </span>
                </div>

            </div>
            <div className="basis-4/12 rounded-lg flex pt-2 pb-2 justify-end items-center">
              <Button
                disabled={props.subscription.isActive && props.subscription.subData.canceled}
                color={"primary"}
                variant={"contained"}
                className="text-white"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleSubscriptionBtnClick(e)}
                sx={{ borderRadius: "20px" }}
              >
                {props.subscription.subData.subscriptionPlanId === 1202 ? "Change Subscription" : !props.subscription.subData.canceled ? "Cancel" : "Change Subscription"}
                {/* {!props.subscription.subData.canceled  ? "Cancel" : props.subscription.subData.subscriptionPlanId === 1202 ? "Upgrade" : "Change Subscription"} */}
              </Button>
            </div>
          </div>
          <div className="flex flex-column 2xl:h-3/6 xl:h-3/6 lg:h-3/6 md:h-3/6 subscription-allowance">
            
             <div className="basis-12/12 mt-12">
                <span>
                  Pdf pages: {props.subscription.subData.pdfPagesAvailable}/{props.subscription.subData.pdfPagesTotal}
                </span>
                <ProgressBar animated={false} variant="success" now={props.subscription.subData.pdfPagesAvailablePercent} label={props.subscription.subData.pdfPagesAvailablePercent + "%"} style={{backgroundColor: "#99cab8"}} />
              </div>
              <div className="basis-12/12 mt-4">
                <span>
                  File words: {props.subscription.subData.wordsAvailable}/{props.subscription.subData.wordsTotal}
                </span>
                <ProgressBar animated={false} variant="success" now={props.subscription.subData.wordsAvailablePercent} label={props.subscription.subData.wordsAvailablePercent + "%"} style={{backgroundColor: "#99cab8"}} />
              </div>
          </div>
        </>
      ) : (
        <>
          <LoadingScreen />
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);


function CircularProgressWithLabel(
  props: CircularProgressProps 
  & { percentvalue: number } 
  & {title: string} 
  & {beforeslash: number}  
  & {afterslash: number}
  & {subtitle: string }
  & {progresscolor: string }
) {



  return (
    <Box sx={{ position: 'relative', display: 'inline-flex',paddingRight:'10px' }}>
      <CircularProgress 
        variant="determinate" 
        sx={{height:'180px !important', width:'180px !important',color:'var(--bs-gray-300)'}} 
        {...props} value={100} />
        <CircularProgress 
        variant="determinate" 
        sx={{height:'180px !important', width:'180px !important',color:props.progresscolor,position:'absolute'}} 
        {...props} value={props.percentvalue} />
       <Box
        sx={{
          top: 40,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}
      >
        <div className="row">
          {
            props.subtitle === '' ? 
              (
                <p style={{fontSize:'23px',color:'var(--bs-gray-700)',marginBottom:'2px'}}>{props.title}</p> 
              )
            : 
              (
                <p style={{fontSize:'23px',color:'var(--bs-gray-700)',marginBottom:'2px'}}>{props.title}<br/>{props.subtitle}</p> 
              )
          }
        <p style={{fontSize:'15px',color:'var(--bs-gray-700)',marginBottom:'2px'}}>{props.beforeslash}/{props.afterslash} </p>
        <p style={{fontSize:'20px',color:'var(--bs-gray-800)'}}>{`${Math.round(props.percentvalue)}%`} </p>
        </div>
      </Box>
    </Box> 
  );
}
