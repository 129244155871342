import { createAction, createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { ProjectType } from "../types/StandardFilesTranslateTypes";

export const PROJECTS_FEATURE_KEY = "projects";

export const generateFetchOrdersListAction = createAction("projects/generateFetchOrdersListAction");
export const generateStripePaymentSuccessAction = createAction<string>("projects/generateStripePaymentSuccessAction");

/*
 * Update these interfaces according to your requirements.
 */
export interface ProjectsEntity extends ProjectType {}

export interface ProjectsState extends EntityState<ProjectsEntity> {}

export const projectsAdapter = createEntityAdapter<ProjectsEntity>({
  selectId: (project) => project.projectId,
});

export const initialLanguagesState: ProjectsState = projectsAdapter.getInitialState();

export const projectsSlice = createSlice({
  name: PROJECTS_FEATURE_KEY,
  initialState: initialLanguagesState,
  reducers: {
    addProject: (state, action: PayloadAction<ProjectsEntity[]>) => {
      projectsAdapter.addMany(state, action.payload);
    },
  },
});

/*
 * Export reducer for store configuration.
 */
export const projectsReducer = projectsSlice.reducer;

export const projectsActions = projectsSlice.actions;

const { selectAll, selectEntities } = projectsAdapter.getSelectors();

export const getProjectsState = (rootState: RootState) => rootState[PROJECTS_FEATURE_KEY];

export const selectAllProjects = createSelector(getProjectsState, selectAll);

export const selectProjectEntities = createSelector(getProjectsState, selectEntities);
