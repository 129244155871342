import React from "react";
import { ReactComponent as NavbarLogo } from "../assets/navbar/navbar-logo.svg";
import { checkToken } from "../helpers/TokenHelper";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AppDispatch, RootState } from "../store/store";
import { getFileState } from "../reducers/file.slice";
import { connect } from "react-redux";
import { generateLoginAction, generateLogoutAction, getControlState } from "../reducers/control.slice";
import { userActions } from "../reducers/user.slice";
import { getLanguagesState, LanguagesState } from "../reducers/languages.slice";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MenuIcon from "@mui/icons-material/Menu";
import ListIcon from "@mui/icons-material/List";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";
import { removeSessionStorageItems } from "../helpers/RedirectHelpers";
import LoyaltyIcon from "@mui/icons-material/Loyalty";

type Options = {
  value: string;
  label: string;
};

interface NavbarProps {
  projectId: string;
  activeStep: number;
  languages: LanguagesState;
  service: string;
  pdfPages: number;
  words: number;

  addUserEmail?(email: string): void;
  onUserLogout?(): void;
  onNewUploadClick?(): void;
}

const mapStateToProps = (state: RootState) => {
  return {
    projectId: getFileState(state).projectId,
    activeStep: getControlState(state).step,
    languages: getLanguagesState(state),
    service: getFileState(state).service,
    pdfPages: getFileState(state).pdfFilesPages,
    words: getFileState(state).totalWordCount,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    addUserEmail: (email: string) => dispatch(userActions.setUserEmail(email)),
    onUserLogout: () => dispatch(generateLogoutAction()),
    onNewUploadClick: () => dispatch(generateLoginAction()),
  }
};

const Navbar: React.FC<NavbarProps> = (props) => {
  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      // borderBottom: '2px dotted green',
      // color: state.isSelected ? 'yellow' : 'black',
      // backgroundColor: state.isSelected ? 'green' : 'white'
    }),
    control: (provided: any) => ({
      ...provided,
      marginTop: "0%",
      length: "100px",
    }),
    container: (provided: any) => ({
      ...provided,
      width: "300px",
      fontFamily: "Heebo",
    }),
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onLogoClick = () => {
    removeSessionStorageItems();
    window.location.href = process.env["REACT_APP_SSO_URL"] as string;
  };
  const onNavbarButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    const text = e.target as HTMLElement;
    if (text.textContent == "Log in") {
      sessionStorage.clear();
      window.location.href =
        "https://api.textunited.com/jsoneditor/auth/login?ReturnUrl=" +
        process.env.REACT_APP_SSO_URL +
        //"https://localhost:44326/login/" +
        `${encodeURIComponent("?")}step=${props.activeStep}${encodeURIComponent("&projectId=")}${props.projectId}` +
        `${encodeURIComponent("&")}targetLang=${props.languages.targetLanguages}` +
        `${encodeURIComponent("&")}sourceLang=${props.languages.sourceLanguage}` +
        `${encodeURIComponent("&")}service=${props.service}` + //${encodeURIComponent(props.projectId)}
        `${encodeURIComponent("&")}pages=${props.pdfPages}` +
        `${encodeURIComponent("&")}words=${props.words}` +
        `${encodeURIComponent("&")}tuft=true`;
      }
    else  if (text.textContent == "Log out"){
        props.onUserLogout?.();
        sessionStorage.clear();
        //window.location.href = process.env.REACT_APP_SSO_URL!;
     }
    else if (text.textContent == "Pricing"){
        window.location.href = 'Pricing';
      }
  };
  const userEmail = checkToken(sessionStorage.getItem("token")!);
  if (userEmail) {
    props.addUserEmail!(userEmail);
  }
  return (
    <div className="navbar">
      <NavbarLogo className="navbar-logo" onClick={onLogoClick} />
      <div className="editor-select">{/*<Select options={options} placeholder="Universal translator" onChange={onSelectChange} styles={customStyles} />*/}</div>
      {props.activeStep != 6 ? (
        <>
          {sessionStorage.getItem("anonymous") == "false" ? (
            <div className="log-in-navbar">
             <Button className="log-out" onClick={(e: React.MouseEvent<HTMLElement>) => onNavbarButtonClick(e)} >
              Pricing
             </Button>
             &nbsp;
             &nbsp;
              <IconButton onClick={(e) => handleClick(e)}>
                <MenuIcon sx={{ color: "white" }} />
              </IconButton>

              <Menu
                id="main-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                  sx: {
                    scale: 0.9,
                    fontWeight: 400,
                    width: "180px",
                    background: "white",
                    "& .MuiAvatar-root": {
                      width: 24,
                      height: 24,
                      ml: -0.5,
                      mr: 1,
                    },
                    "& #projects-list": {
                      width: 24,
                      height: 24,
                      ml: -0.5,
                      mr: 1,
                    },
                    "& #log-out-icon": {
                      width: 24,
                      height: 24,
                      ml: -0.5,
                      mr: 1,
                    },
                    "& #subscription, #profile, #file-upload": {
                      width: 24,
                      height: 24,
                      ml: -0.5,
                      mr: 1,
                    },
                    "& a": { textDecoration: "none", color: "black" },
                  },
                }}
              >
                <Link  to="/uploadfile" >
                  <MenuItem onClick={handleClose}>
                    <UploadFileIcon id="file-upload" />
                    <span>Upload file</span>
                  </MenuItem>
                </Link>
                <Link to="/profile">
                  <MenuItem onClick={handleClose}>
                    <AccountCircleIcon id="profile" />
                    <span>Profile</span>
                  </MenuItem>
                </Link>
                <Link to="/orders">
                  <MenuItem onClick={handleClose}>
                    <ListIcon id="projects-list" /> <span>Orders</span>
                  </MenuItem>
                </Link>
                <Link to="/subscription">
                  <MenuItem onClick={handleClose}>
                    <LoyaltyIcon id="subscription" />
                    <span>Subscription</span>
                  </MenuItem>
                </Link>
                <MenuItem
                  onClick={(e) => {
                    props.onUserLogout?.();
                    sessionStorage.clear();
                    handleClose();
                  }}
                >
                  <LogoutIcon id="log-out-icon" /> Log out
                </MenuItem>
              </Menu>
            </div>
          ) : (
           <div>
             
            
            <Button className="log-out" onClick={(e: React.MouseEvent<HTMLElement>) => onNavbarButtonClick(e)}>
              Log in
            </Button>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
