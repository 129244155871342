import React from "react";

export interface PasswordStrengthProps {
  errors: string;
}

const PasswordStrength: React.FC<PasswordStrengthProps> = (props: PasswordStrengthProps) => {
  const changePasswordColor = () => {
    const validation = props.errors;
    let score: number;
    if (validation == undefined) {
      score = 0;
    } else {
      score = validation == "" ? 0 : validation.split(",").length;
    }
    let result = {
      width: "0%",
      background: "#D0D1D3",
    };
    switch (score) {
      case 4: {
        result = {
          width: "25%",
          background: "red",
        };
        break;
      }
      case 3: {
        result = {
          width: "50%",
          background: "#FD902B",
        };
        break;
      }
      case 2: {
        result = {
          width: "75%",
          background: "#FDD652",
        };
        break;
      }
      case 1: {
        result = {
          width: "85%",
          background: "#FF9B21",
        };
        break;
      }
      case 0: {
        result = {
          width: "100%",
          background: "#0A35E0",
        };
        break;
      }
    }

    return result;
  };
  return (
    <div className="progress mt-2" style={{ height: "7px" }}>
      <div className="progress-bar" role="progressbar" style={changePasswordColor()}></div>
    </div>
  );
};

export default PasswordStrength;
