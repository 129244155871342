import React, { SyntheticEvent, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import Modal from "react-modal";
import { FileState, getFileState } from "../../reducers/file.slice";
import { AppDispatch, RootState } from "../../store/store";
import { connect } from "react-redux";
import InfoIcon from "../svgIcons/InfoIcon";
import { Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { MACHINE_TRANSLATION_NAME } from "../../editorConstants";
import { generateDownloadMachineTranslationFileAction, generateLoginAction, generateUserRateAction } from "../../reducers/control.slice";

interface TranslationSuccessModalProps {
  file: FileState;

  onUserRate?(rate: number): void;

  onDownloadClick?(): void;

  onStartAnotherJobClick?(): void;
}

const mapStateToProps = (state: RootState) => {
  return {
    file: getFileState(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onUserRate: (rate: number) => dispatch(generateUserRateAction({ rate })),
    onDownloadClick: () => dispatch(generateDownloadMachineTranslationFileAction()),
    onStartAnotherJobClick: () => dispatch(generateLoginAction()),
  };
};

const TranslationSuccessModal: React.FC<TranslationSuccessModalProps> = (props: TranslationSuccessModalProps) => {
  const [value, setValue] = useState(0);
  const onRatingChange = (e: SyntheticEvent, newValue: number) => {
    setValue(newValue as number);
    props.onUserRate?.(newValue);
  };
  const clearSessionStorage = () => {
    props.onStartAnotherJobClick?.();
  };
  return (
    <Modal isOpen={props.file?.translationIsReady} className="modal-90w animate__animated animate__fadeInDownBig" ariaHideApp={false}>
      <div className="row g-0" style={{ height: "100%" }}>
        <div className="left col-12 col-lg-6">
          <div className="translate-text text-center mx-5 pt-5">
            <span>{props.file.service == MACHINE_TRANSLATION_NAME ? "Translated files are ready!" : "Thank you!"}</span>
          </div>
          {props.file.service == MACHINE_TRANSLATION_NAME ? (
            <div className={"text-center mt-4"}>
              <Button className=" btn-singup mt-5" disabled={!props.file?.translationIsReady} onClick={() => props.onDownloadClick?.()}>
                Download
              </Button>
            </div>
          ) : props.file.isOfferRequired ? (
            <div>
              <p className="modal-text-left pt-4">
                We received your project. <br />
                We will send you offer as soon as possible . <br /> The project ID is: {props.file.textUnitedProjectId}
              </p>
            </div>
          ) : (
            <div>
              <p className="modal-text-left text-center pt-4">
                We received the payment and are <br />
                starting work on your project. <br /> The project ID is: {props.file.textUnitedProjectId}
              </p>
            </div>
          )}
          <div className="modal-info-text">
            <InfoIcon /> support@textunited.com
          </div>
        </div>
        <div className="right col-12 col-lg-6 pt-5 ps-5">
          <span>
            Do you like what <br></br> you see?
          </span>
          <div className="rating text-center mt-5">
            <Rating
              value={value}
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
              onChange={(event, newValue) => {
                onRatingChange(event, newValue as number);
              }}
            />
          </div>
          <div className="text-center">
            <Button className={`btn-singup btn-5 ${props.file.service == MACHINE_TRANSLATION_NAME ? "btn-start-another-job-mt" : "btn-start-another-job"}`} onClick={() => clearSessionStorage()}>
              START ANOTHER JOB
            </Button>
          </div>
          {props.file.service == MACHINE_TRANSLATION_NAME ? null : (
            <div className="row d-flex justify-content-center mt-4">
              <Button className="btn-singup" onClick={() => ((window.location.href = "https://www.textunited.com/my/"), sessionStorage.clear())}>
                GO TO DASHBOARD
              </Button>
            </div>
          )}
          <button className="bt-close" onClick={() => clearSessionStorage()}>
            <FontAwesomeIcon icon={faXmark} size="3x" />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TranslationSuccessModal);
