import React from "react";
import "./index.scss";
import App from "./App";
import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { OpenUrl } from "./services/sso";
import * as signalR from "@microsoft/signalr";
import { LogLevel } from "@microsoft/signalr";
import { MachineTranslationResponse } from "./connector/models/apiModels";
import { fileActions } from "./reducers/file.slice";
import { generateShowErrorAction } from "./reducers/control.slice";
import { BrowserRouter } from "react-router-dom";

// @ts-ignore fix this later
import { gtag, install, initDataLayer } from "ga-gtag";
import { initSentry } from "./services/sentry";



const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

install("G-KHS5LY95VQ");
initDataLayer();
gtag("config", "G-KHS5LY95VQ", {
  linker: {
    domains: ["checkout.stripe.com"],
  },
  
});
gtag("consent", "default", {
  ad_storage: "denied",
  ad_user_data: "denied", 
  ad_personalization: "denied",
  analytics_storage: "denied",
  functionality_storage: "denied",
  personalization_storage: "denied",
  security_storage: "granted",
  wait_for_update: 2000,
});
gtag("set", "ads_data_redaction", true);
gtag("set", "url_passthrough", true);
install("G-KHS5LY95VQ");

//TODO: check how to use it

export const hubConnection = new signalR.HubConnectionBuilder().configureLogging(LogLevel.Error).withUrl("https://api.textunited.com/docprocessor/MachineTranslationStatusHub").withAutomaticReconnect().build();
hubConnection.on("MachineTranslationDone", (message: MachineTranslationResponse) => {
  if (message.machineTranslationStatusName == "Done") {
    store.dispatch(fileActions.changeTranslationStatus(true));
    store.dispatch(fileActions.loading(false));
  } else {
    store.dispatch(generateShowErrorAction({ show: true, action: "serverError" }));
  }
});

const botPattern = "(googlebot\/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
const re = new RegExp(botPattern, 'i');
var userAgent = navigator.userAgent; 
if (!re.test(userAgent)) {
  OpenUrl("https://api.textunited.com/jsoneditor/auth/login?ReturnUrl=" + document.location.origin + "&checkOnly=Y");
}
//OpenUrl("https://api.textunited.com/jsoneditor/auth/login?ReturnUrl=" + process.env.REACT_APP_SSO_URL + "&checkOnly=Y");




initSentry();

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <StrictMode>
        <App />
      </StrictMode>
    </BrowserRouter>
  </Provider>
);
