import * as Sentry from "@sentry/react";
import { httpClientIntegration } from "@sentry/integrations";

const sentryDNS = process.env.SENTRY_DNS! as string;
let release = "standard-fiels-translator@0.2.5";
export function initSentry() {
  Sentry.init({
    dsn: "https://6243ebc05e6428cc8e2590199d9e10a5@o4506021109170176.ingest.sentry.io/4506021114216448",
    integrations: [ Sentry.browserTracingIntegration(),  Sentry.replayIntegration(), httpClientIntegration()],

    tracesSampleRate: 1.0,

    tracePropagationTargets: ["localhost", /^https:\/\/translate.textunited.com\.io\/api/],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
