import { Card, CardContent } from '@mui/material';
import React from 'react';


interface FAQProps {

}

const FAQ : React.FC<FAQProps> = (props:FAQProps) => {
/*
bs-gray-800: #343a40;
  --bs-gray-900: #212529;
*/ 
return (
    <div className="container container-xxl ps-5 pe-5 pt-2">
        <div className="row ">
            <Card>
                <CardContent >
                <div className="row pt-2 pb-5"> 
                    <h3 style={{fontWeight:'bold', color:'var(--bs-primary)'}}>Frequently Asked Questions</h3>
                    </div>
                    <div className="row ">
                    <h5 style={{fontWeight:'bold', color:'var(--bs-gray-900)'}} >How does it all work?</h5>
                    
                    <p style={{fontStyle:'normal', color:'var(--bs-gray-800)'}}>The system can extract content for translation from more than 30 file formats and PDF files. The extracted content is cleaned up and prepared for translation by advanced neural machine translation engines. Once the translation process is complete, the system generates the translated files in the original format and layout. In the case of PDF files, the translated file is returned as a Word document, which you can edit as per your requirement.
</p>
</div>
<div className="row ">
<h5 style={{fontWeight:'bold', color:'var(--bs-gray-900)'}}>Why does the layout of a translated PDF file differ from the original?</h5>

<p style={{fontStyle:'normal', color:'var(--bs-gray-800)'}}>When automatically translating PDF files, be prepared for differences in layout due to varying content. Human involvement is usually necessary for a perfect layout.
</p>
</div>
<div className="row ">
<h5 style={{fontWeight:'bold', color:'var(--bs-gray-900)'}}>Which translation engines are used?</h5>

<p style={{fontStyle:'normal', color:'var(--bs-gray-800)'}}>We dynamically select the best translation engine for your task and use the top enterprise systems from Google, Microsoft, and Amazon. Our automated quality assurance process incorporates a robust local language model to identify and correct errors.
</p>
</div>
<div className="row ">
<h5 style={{fontWeight:'bold', color:'var(--bs-gray-900)'}}>What is the quality of translations?</h5>

<p style={{fontStyle:'normal', color:'var(--bs-gray-800)'}}>It is essential to consider the limitations of automated translations. While they can be helpful in many situations, having a human translator verify and correct the translations is highly recommended. In some industries, entirely replacing automated translations with human translations is necessary to meet regulatory requirements. Our system offers all these options to ensure the right quality level for your use case.
</p>
</div>
<div className="row ">
<h5 style={{fontWeight:'bold', color:'var(--bs-gray-900)'}}>How many pages can I translate for free?</h5>

<p style={{fontStyle:'normal', color:'var(--bs-gray-800)'}}>Our welcome package for new users includes 25 PDF pages and up to 10,000 words in editable documents. If you're using our free edition, you'll receive 5 PDF pages and up to 2,500 words in editable documents each month at no cost. To increase your monthly allowances, upgrade to our premium offering.
</p>
</div>
                </CardContent>
            </Card>
        </div>
    </div>
)

}

export default FAQ;