import React, { SyntheticEvent, useState } from "react";
import { FileState, getFileState } from "../reducers/file.slice";
import { AppDispatch, RootState } from "../store/store";
import { connect } from "react-redux";
import LoadingScreen from "./LoadingScreen";
import MachineTranslationSuccessModal from "./modals/TranslationSuccessModal";
import { MACHINE_TRANSLATION_NAME } from "../editorConstants";
import { ControlTypes, SubscriptionType } from "../types/StandardFilesTranslateTypes";
import { generateDownloadMachineTranslationFileAction, generateLoginAction, generateUserRateAction, getControlState } from "../reducers/control.slice";
import { useMediaQuery } from "@mui/material";
import TranslationSuccessMobileModal from "./modals/TranslationSuccessMobileModal";
import { Button } from "@mui/material";
import InfoIcon from "./svgIcons/InfoIcon";
import { generateSubscriptionBuyAction, getSubscriptionState } from "../reducers/subscription.slice";

interface DownloadFileProps {
  file: FileState;
  controls: ControlTypes;
  subscription: SubscriptionType;

  onUserRate?(rate: number): void;

  onDownloadClick?(): void;

  onStartAnotherJobClick?(): void;

  onSubscriptionButtonClick(): void;
}

const mapStateToProps = (state: RootState) => {
  return {
    file: getFileState(state),
    controls: getControlState(state),
    subscription: getSubscriptionState(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onUserRate: (rate: number) => dispatch(generateUserRateAction({ rate })),
    onDownloadClick: () => dispatch(generateDownloadMachineTranslationFileAction()),
    onStartAnotherJobClick: () => dispatch(generateLoginAction()),
    onSubscriptionButtonClick: () => dispatch(generateSubscriptionBuyAction()),
  };
};

const DownloadFile: React.FC<DownloadFileProps> = (props: DownloadFileProps) => {
  function Media() {
    const matches = useMediaQuery("(max-width: 600px)");
    if (matches) {
      return <TranslationSuccessMobileModal />;
    } else {
      return <MachineTranslationSuccessModal />;
    } //<span>{`(min-width: 600px) matches: ${matches}`}</span>
  }

  const [value, setValue] = useState(0);
  const [active, setActive] = useState("");
  const onRatingChange = (e: SyntheticEvent, newValue: number) => {
    setValue(newValue as number);
    props.onUserRate?.(newValue);
  };
  const clearSessionStorage = () => {
    props.onStartAnotherJobClick?.();
  };
  const startJobRef = React.useRef(null);
  return (
    <>
      {props.file?.isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="card mt download-container animate__animated animate__fadeInRight">
          <div className="row" style={{ height: "100%" }}>
            <div className="left col-12 col-lg-12">
              <div className="translate-text text-center">
                <span>{props.file.service == "Instant" ? "Translated files are ready!" : "Thank you!"}</span>
              </div>
              {props.file.service == MACHINE_TRANSLATION_NAME ? (
                <div className={"text-center"}>
                  <Button
                    className=" btn-singup"
                    onClick={() => {
                      props.onDownloadClick?.();
                      setActive("btn-startJob-active");
                    }}
                  >
                    Download
                  </Button>
                </div>
              ) : props.file.isOfferRequired ? (
                <div>
                  <p className="modal-text-left">
                    We received your project. <br />
                    We will send you offer as soon as possible. <br /> The project ID is: {props.file.textUnitedProjectId}
                  </p>
                </div>
              ) : (
                <div>
                  <p className="modal-text-left text-center">
                    We received the payment and are <br />
                    starting work on your project. <br /> The project ID is: {props.file.textUnitedProjectId}
                  </p>
                </div>
              )}
              <div className="text-center">
                <Button className={`btn-singup btn-5 ${active} ${props.file.service == MACHINE_TRANSLATION_NAME ? "btn-start-another-job-mt" : "btn-start-another-job"}`} onClick={() => clearSessionStorage()} ref={startJobRef}>
                  Start another job
                </Button>
              </div>
              <div className="modal-info-text">
                <InfoIcon /> support@textunited.com
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadFile);
