import React, { FC } from "react";
import { Alert, AlertColor } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { ControlTypes } from "../types/StandardFilesTranslateTypes";
import { AppDispatch, RootState } from "../store/store";
import { controlActions, getControlState } from "../reducers/control.slice";
import { connect } from "react-redux";

interface AlertSnackbarProps {
  isOpen: boolean;
  message: string;
  severity: AlertColor;
  control: ControlTypes;

  onCloseClick(): void;
}

const mapStateToProps = (sate: RootState) => {
  return {
    control: getControlState(sate),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onCloseClick: () => dispatch(controlActions.hideAllPopUps()),
  };
};
const AlertSnackbar: FC<AlertSnackbarProps> = (props: AlertSnackbarProps) => {


  return (
    <div>
      <Snackbar open={props.isOpen} autoHideDuration={10000} onClose={props.onCloseClick} anchorOrigin={{ horizontal: "center", vertical: "top" }}>
        <Alert onClose={props.onCloseClick} severity="error" sx={{ width: "100%" }}>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertSnackbar);
