import React from 'react';
import {AppDispatch} from "../store/store";
import { controlActions} from "../reducers/control.slice";

import {connect} from "react-redux";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface ErrorProps {
  className?: string,
  onCloseClick?(): void,
  setAnimation?(animationName: string):void,
  text?:string,
  show: boolean,
}

// const mapStateToProps = (state:RootState):ErrorProps => {
//   return{
//     control: selectControlState(state),
//   }
// };

const mapDispatchToProps = (dispatch:AppDispatch) => {
  return{
    onCloseClick:() => dispatch(controlActions.closeErrorWindow()),
    setAnimation:(animationName: string) => dispatch(controlActions.setAnimationClass(animationName))

  }

}

const Error:React.FC<ErrorProps> = (props:ErrorProps) => {
  const onBtnClickHandler = () => {
    props.setAnimation!("animate__fadeOut");
    setTimeout(() => {props.onCloseClick!();}, 500);
  };
  return(
    <div className="row" hidden={!props.show}>
      <div className={`error-msg text-center animate__animated ${props.className}`}>
        <p>{props.text}</p>
        <button className="x-mark" onClick={() => onBtnClickHandler()}>
          <FontAwesomeIcon icon={faXmark} size="3x" />
        </button>
      </div>
    </div>
  )
}

export default connect( null,mapDispatchToProps,) (Error);
