import React from "react";
import {ReactComponent as AIcon} from "../../assets/stepper/stepper-active.svg";
import SvgIcon from "@mui/material/SvgIcon";

const ActiveIcon = () => (
  <SvgIcon>
    <AIcon />
  </SvgIcon>
);

export default ActiveIcon;
