import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Button, FormControl, TextField, TextFieldProps, useMediaQuery } from "@mui/material";
import { generateGetUserData, generateUpdateUserDataAction, generateUpdateUserTaxTypeAction, getUserState, userActions, UserState } from "../reducers/user.slice";
import { AppDispatch, RootState } from "../store/store";
import { connect } from "react-redux";
import { Country, TaxType } from "../connector/models/apiModels";
import { generatorFetchLanguages, getLanguagesState } from "../reducers/languages.slice";
import Enumerable from "linq";
import Select, { ActionMeta, SelectInstance, SingleValue } from "react-select";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link, useNavigate } from "react-router-dom";
import { Address, Company, ControlTypes, UserRegisterInfo } from "../types/StandardFilesTranslateTypes";
import AlertSnackbar from "./AlertSnackbar";
import { controlActions, getControlState } from "../reducers/control.slice";
import LoadingScreen from "./LoadingScreen";
import { TextFieldValidation } from "../actions/actionsTypes";
import { date } from "yup";

interface ProfileProps {
  user: UserState;
  countries: Country[];
  control: ControlTypes;

  onUserInfoUpdate?(data: UserRegisterInfo): void;

  onCompanyInfoUpdate?(data: Address, data2: Company): void;

  changeCountryId(countryId: number): void;

  onSave(): void;

  onTaxTypeUpdate(): void;

  onUserInfoValidationFinish(show: boolean): void;

  getUserData(): void;

  onTextFieldValidation(field: TextFieldValidation): void;

  onTaxTypeChange(taxType: string): void;

  onTaxNumberEndChange(taxNumber: string): void;
  
}

interface FieldValidationProps {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  companyName: boolean;
  city: boolean;
  street: boolean;
  zipCode: boolean;
  taxNumber: boolean;
}

const mapStateToProps = (state: RootState) => {
  
  return {
    user: getUserState(state),
    countries: state.user.countries,
    control: getControlState(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    changeCountryId: (countryId: number) => dispatch(userActions.setCountryId(countryId)),
    onUserInfoUpdate: (data: UserRegisterInfo) => dispatch(userActions.addUserRegisterInfo(data)),
    onCompanyInfoUpdate: (data: Address, data2: Company) =>
      dispatch(
        userActions.addCompanyRegisterInfo({
          address: data,
          company: data2,
        })
      ),
    onSave: () => dispatch(generateUpdateUserDataAction()),
    onTaxTypeUpdate: () => dispatch(generateUpdateUserTaxTypeAction()),
    onUserInfoValidationFinish: (show: boolean) => dispatch(controlActions.setUserInfoAlert(true)),
    getUserData: () => dispatch(generateGetUserData()),
    onTextFieldValidation: (field: TextFieldValidation) => dispatch(controlActions.setValidationTextField(field)),
    onTaxTypeChange: (taxType: string) => dispatch(userActions.setTaxType(taxType)),
    onTaxNumberEndChange: (taxNumber: string) => dispatch(userActions.setTaxNumber(taxNumber)),
    
  };
};

const Profile: React.FC<ProfileProps> = (props: ProfileProps) => {
  //#region media query
  const heightDefinition = useMediaQuery("(-webkit-min-device-pixel-ratio: 1.5)");
  //#endregion
  //#region Styles
  const nameTextFieldStyle = {
    zIndex: 0,
    //width: heightDefinition ? { sm: 350, md: 320 } : { sm: 350, md: 375 },
    width: "80%",
    "& .MuiInputBase-root": {
      height: heightDefinition ? 40 : 50,
    },
    "& .MuiInputLabel-root": {
      fontSize: heightDefinition ? "14px" : "18px",
    },
  };

  const otherTextFiledStyle = {
    zIndex: 0,
    width: "90%",
    "& .MuiInputBase-root": {
      height: heightDefinition ? 40 : 50,
      fontSize: heightDefinition ? "14px" : "18px",
    },
    "& .MuiInputLabel-root": {
      fontSize: heightDefinition ? "14px" : "18px",
    },
  };
  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      color: "black",
      backgroundColor: "white",
      zIndex: 9999,

    }),
    control: (provided: any) => ({
      ...provided,
      marginTop: "0",
      length: "100px",
      borderRadius: "6px",
      height: heightDefinition ? "30px" : "46px",
      width: "95%",
      fontSize: "14px",
      fontWeight: "bold",
      fontFamily: "Heebo",
    }),
    container: (provided: any) => ({
      ...provided,
      width: "95%",
      fontFamily: "Heebo",
      backGroundColor: "red !important",
      fontSize: "16px"
    }),
  };
  //#endregion

  useEffect(() => {
    if (!props.user.isDataLoaded) {
      props.getUserData();
    }
  }, []);

  //#region State
  const [showSave, setShowSave] = useState<boolean>(false);
  //#endregion

  let taxTypeList = Enumerable.from(props.countries)
    .where((x) => x.id == props.user.countryId)
    .select((element) => element.taxTypes)
    .toArray();

  //#region References
  const taxTypeRef = React.createRef<SelectInstance<TaxType>>();
  const companyCountryRef = React.createRef<SelectInstance<Country>>();
  const firstNameRef = useRef<TextFieldProps>(null);
  const lastNameRef = useRef<TextFieldProps>(null);
  const emailRef = useRef<TextFieldProps>(null);
  const companyNameRef = useRef<TextFieldProps>(null);
  const cityRef = useRef<TextFieldProps>(null);
  const streetRef = useRef<TextFieldProps>(null);
  const zipCodeRef = useRef<TextFieldProps>(null);
  const taxNumberRef = useRef<TextFieldProps>(null);
  //#endregion

  //#region Handlers
  const onChange = (option: SingleValue<TaxType>) => {
    setShowSave(true);
    if (option != null) {
      props.onTaxTypeChange(option!.stripeCode);
      props.onTaxTypeUpdate();
    }
  };

  const onCountryChange = (option: SingleValue<Country>, actionMeta: ActionMeta<Country>) => {
    setShowSave(true);
    props.changeCountryId(option!.id);
    taxTypeRef.current?.clearValue();
    props.onTaxTypeChange("");
  };

  const handleSaveBtnClick = () => {
    if (Object.values(props.control.fieldValidation).some((x) => x == true)) {
      props.onUserInfoValidationFinish(true);
      return;
    }

    const data: UserRegisterInfo = {
      firstName: (firstNameRef.current?.value as string) ?? (props.user.firstName as string),
      lastName: (lastNameRef.current?.value as string) ?? (props.user.lastName as string),
      email: (emailRef.current?.value as string) ?? (props.user.email as string),
      password: "",
    };

    props.onUserInfoUpdate!(data);

    const addressData: Address = {
      city: (cityRef.current?.value as string) ?? props.user.company.address?.city,
      address1: (streetRef.current?.value as string) ?? props.user.company.address?.address1,
      zipCode: (zipCodeRef.current?.value as string) ?? props.user.company.address?.zipCode,
      countryId: props.user.countryId,
    };
 
    const companyData: Company = {
      name: (companyNameRef.current?.value as string) ?? props.user.company.name,
      address: addressData,
    };

    props.onCompanyInfoUpdate!(addressData, companyData);

    props.onSave!();
  };
  //#endregion

  //#region Validation TextField
  const onTextFieldChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setShowSave(true);
    let field = e.target as HTMLInputElement;
    switch (field.id) {
      case "firstNameTX": {
        field.value == ""
          ? props.onTextFieldValidation({
              field: "firstName",
              validationResult: true,
            })
          : props.onTextFieldValidation({ field: "firstName", validationResult: false });
        break;
      }
      case "lastNameTX": {
        field.value == ""
          ? props.onTextFieldValidation({
              field: "lastName",
              validationResult: true,
            })
          : props.onTextFieldValidation({ field: "lastName", validationResult: false });
        break;
      }
      case "emailTX": {
        field.value == ""
          ? props.onTextFieldValidation({
              field: "email",
              validationResult: true,
            })
          : props.onTextFieldValidation({ field: "email", validationResult: false });
        break;
      }
      case "companyNameTX": {
        field.value == ""
          ? props.onTextFieldValidation({
              field: "companyName",
              validationResult: true,
            })
          : props.onTextFieldValidation({ field: "companyName", validationResult: false });
        break;
      }
      case "cityTX": {
        field.value == ""
          ? props.onTextFieldValidation({
              field: "city",
              validationResult: true,
            })
          : props.onTextFieldValidation({ field: "city", validationResult: false });
        break;
      }
      case "streetTX": {
        field.value == ""
          ? props.onTextFieldValidation({
              field: "street",
              validationResult: true,
            })
          : props.onTextFieldValidation({ field: "street", validationResult: false });
        break;
      }
      case "zipCodeTX": {
        field.value == ""
          ? props.onTextFieldValidation({
              field: "zipCode",
              validationResult: true,
            })
          : props.onTextFieldValidation({ field: "zipCode", validationResult: false });
        break;
      }
      case "taxNumberTX": {
        field.value == ""
          ? props.onTextFieldValidation({
              field: "taxNumber",
              validationResult: true,
            })
          : props.onTextFieldValidation({ field: "taxNumber", validationResult: false });
        if (field.value != "") {
          props.onTaxNumberEndChange(field.value);
          props.onTaxTypeUpdate();
        }
        break;
      }
    }
  };
  //#endregion

  const navigate = useNavigate();



  return (
    <div className="p-6 max-w-screen-2xl h-screen mt-5 mx-auto rounded-xl shadow-lg items-center space-x-4 profile xl:h-screen xl:scale-80">
      <AlertSnackbar isOpen={props.control.userInfoAlert} severity={"error"} message={"There are empty fields in form"} />
      <AlertSnackbar isOpen={props.control.taxNumberAlert} message={"Invalid tax number"} severity={"error"} />
      {props.user.isDataLoaded ? (
        <>
          <div className=" flex flex-row justify-between ml-4">
            <div className="flex flex-row align-baseline items-center">
              <Link to={"/"}>
                <ArrowBackIosIcon color="info" />
              </Link>
              <span className="font-bold 2xl:text-5xl xl:text-3xl">Profile</span>
            </div>

            <Button className="btn-new" disabled={!showSave} type="submit" onClick={() => handleSaveBtnClick()}>
              Save
            </Button>
          </div>
          <FormControl sx={{ width: "100%" }} className="text-xs">
            <div className="flex flex-row mt-8">
              <div className="basis-2/6 mt-3 profile-section 2xl:text-2xl xl:text-xl">Personal settings</div>
              <div className="basis-4/6 2xl:mt-3 xl:mt-3.5 lg:mt-3">
                <div className="flex flex-col">
                  <div className="mt-0 flex flex-row gap-2">
                    <div className="basis-1/2">
                      <div className="2xl:mt-1 xl:mt-0.5 lg:mt-0.5">
                        <TextField
                          error={props.control.fieldValidation.firstName}
                          inputRef={firstNameRef}
                          onBlur={(e) => onTextFieldChange(e)}
                          size="small"
                          className="text-field"
                          id="firstNameTX"
                          variant="outlined"
                          label="First name"
                          defaultValue={props.user.firstName}
                          sx={nameTextFieldStyle}
                        />
                      </div>
                    </div>
                    <div className="basis-1/2">
                      <div className="2xl:mt-1 xl:mt-0.5 lg:mt-0.5">
                        <TextField
                          error={props.control.fieldValidation.lastName}
                          inputRef={lastNameRef}
                          onBlur={(e) => onTextFieldChange(e)}
                          size="small"
                          className="text-field"
                          id="lastNameTX"
                          variant="outlined"
                          label="Second name"
                          defaultValue={props.user.lastName}
                          sx={nameTextFieldStyle}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="2xl:mt-3 xl:mt-1.5 lg:mt-1">
                    <div className="2xl:mt-1 xl:mt-0.5 lg:mt-0.5">
                      <TextField
                        sx={otherTextFiledStyle}
                        error={props.control.fieldValidation.email}
                        inputRef={emailRef}
                        onBlur={(e) => onTextFieldChange(e)}
                        size="small"
                        className="text-field"
                        id="emailTX"
                        label="Email address"
                        variant="outlined"
                        defaultValue={props.user.email}
                        
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row personal-data">
              <div className="basis-2/6 profile-section mt-3 2xl:text-2xl xl:text-xl">Company settings</div>
              <div className="basis-4/6 mt-3">
                <div className="flex flex-col">
                  <div className="mt-0">
                    <div className="2xl:mt-1 xl:mt-0.5 lg:mt-0.5">
                      <TextField
                        error={props.control.fieldValidation.companyName}
                        inputRef={companyNameRef}
                        onBlur={(e) => onTextFieldChange(e)}
                        size="small"
                        className="text-field"
                        id="companyNameTX"
                        variant="outlined"
                        label="Company name"
                        defaultValue={props.user.company.name}
                        
                        sx={otherTextFiledStyle}
                      />
                    </div>
                  </div>
                  <div className="2xl:mt-3 xl:mt-1.5 lg:mt-1">
                    <div className="2xl:mt-1 xl:mt-0.5 lg:mt-0.5">
                      <TextField
                        sx={otherTextFiledStyle}
                        error={props.control.fieldValidation.city}
                        inputRef={cityRef}
                        onBlur={(e) => onTextFieldChange(e)}
                        size="small"
                        className="text-field"
                        id="cityTX"
                        variant="outlined"
                        label="City"
                        defaultValue={props.user.address.city}
                       
                      />
                    </div>
                  </div>
                  <div className="2xl:mt-3 xl:mt-1.5 lg:mt-1">
                    <div className="2xl:mt-1 xl:mt-0.5 lg:mt-0.5">
                      <TextField
                        sx={otherTextFiledStyle}
                        error={props.control.fieldValidation.street}
                        inputRef={streetRef}
                        onBlur={(e) => onTextFieldChange(e)}
                        size="small"
                        className="text-field"
                        id="streetTX"
                        variant="outlined"
                        label="Street"
                        defaultValue={props.user.address.address1}
                        
                      />
                    </div>
                  </div>
                  <div className="2xl:mt-3 xl:mt-1.5 lg:mt-1">
                    <div className="2xl:mt-1 xl:mt-0.5 lg:mt-0.5">
                      <TextField
                        error={props.control.fieldValidation.zipCode}
                        inputRef={zipCodeRef}
                        onBlur={(e) => onTextFieldChange(e)}
                        size="small"
                        className="text-field"
                        id="zipCodeTX"
                        variant="outlined"
                        label="Zip code"
                        defaultValue={props.user.address.zipCode}
                        sx={otherTextFiledStyle}
                        
                      />
                    </div>
                  </div>
                  <div className="2xl:mt-3 xl:mt-1.5 lg:mt-1">
                    <div className="2xl:mt-1 2xl:text-xl xl:mt-0.5 xl:text-xs lg:mt-0.5">
                      <Select
                        styles={customStyles}
                        ref={companyCountryRef}
                        placeholder={"Country"}
                        getOptionLabel={(op) => op.countryName}
                        options={props.countries}
                        noOptionsMessage={() => "Select a country"}
                        onChange={(newValue, actionMeta) => onCountryChange(newValue, actionMeta)}
                        defaultValue={props.countries.filter((x) => x.id == props.user.company.address?.countryId)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row personal-data">
              <div className="basis-2/6 mt-3 profile-section 2xl:text-2xl xl:text-xl">Tax information</div>
              <div className="basis-4/6 mt-3">
                <div className="flex flex-col">
                  <div className="mt-0 flex flex-row"></div>
                  <div className="2xl:mt-3 xl:mt-1.5 lg:mt-1">
                    <div className="2xl:mt-1 xl:mt-0.5 lg:mt-0.5">
                      <TextField
                        error={props.control.fieldValidation.taxNumber}
                        inputRef={taxNumberRef}
                        onBlur={(e) => onTextFieldChange(e)}
                        className="text-field"
                        id="taxNumberTX"
                        size="small"
                        label="Tax number"
                        variant="outlined"
                        defaultValue={props.user.taxNumber}
                        sx={otherTextFiledStyle}
                      />
                    </div>
                  </div>
                  <div className="2xl:mt-3 xl:mt-1.5 lg:mt-1">
                    <div className="2xl:mt-1 2xl:text-xl xl:mt-0.5 xl:text-xs lg:mt-0.5">
                      {taxTypeList[0] != undefined ? (
                        <Select
                          ref={taxTypeRef}
                          styles={customStyles}
                          placeholder={"Tax type"}
                          getOptionLabel={(op) => op.name}
                          onChange={onChange}
                          options={taxTypeList[0]}
                          defaultValue={taxTypeList[0].filter((x) => x.stripeCode == props.user.taxTypeCode)}
                          noOptionsMessage={() => "Select a country"}
                          isSearchable={false}
                        />
                      ) : (
                        <Select 
                        styles={customStyles} 
                        placeholder={"Tax type"} 
                        getOptionLabel={(op) => op.name} 
                        onChange={onChange} 
                        options={taxTypeList[0]} 
                        noOptionsMessage={() => "Select a country"} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FormControl>
        </>
      ) : (
        <>
          <LoadingScreen />
        </>
      )}
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
