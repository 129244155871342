import React from "react";
import { RootState } from "../../store/store";
import Modal from "@mui/material/Modal";
import { getControlState } from "../../reducers/control.slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { Box } from "@mui/material";
import { removeSessionStorageItems } from "../../helpers/RedirectHelpers";

interface ErrorModalProps {
  showError: boolean;
  text: string;
}

const mapStateToProps = (state: RootState) => {
  return {
    showError: getControlState(state).showModalError,
    text: getControlState(state).errorMessage,
  };
};

const ErrorModal: React.FC<ErrorModalProps> = (props) => {
  function onBtnClickHandler() {
    removeSessionStorageItems();
    window.location.reload();
  }

  return (
    <Modal open={props.showError} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className={"error-modal"}>
        <div className="error-icon">
          <FontAwesomeIcon icon={faXmark} size={"2x"} onClick={() => onBtnClickHandler()} />
        </div>
        <div className="row px-5 pt-5">
          <span>{props.text}</span>
        </div>
      </Box>
    </Modal>
  );
};

export default connect(mapStateToProps)(ErrorModal);
