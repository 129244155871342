import React, { FC } from "react";

interface ProjectRowProps {
  projectId: string;
  serviceName: string;
  languagesList: string;
  sourceLang: string;
  date: string;
  price: string;
}

const ProjectRow: FC<ProjectRowProps> = (props: ProjectRowProps) => {
  return (
    <div className="card rounded-lg mx-1 my-2 order-item xl:h-12">
      <div className="flex flex-row space-x-4 2xl:text-sm xl:text-xs leading-6 mx-5 text-base">
        <div className="basis-1/3 h-14 rounded-lg flex items-center justify-start text-blue-700">{props.projectId}</div>
        <div className="basis-1/3 h-14 rounded-lg flex items-center justify-start">{props.serviceName}</div>
        <div className="basis-1/6 h-14 rounded-lg flex items-center justify-start ">
          <span>
            {props.sourceLang} {`->`} {props.languagesList}
          </span>
        </div>
        <div className="basis-1/12 h-14 rounded-lg flex items-center justify-start ">{props.date.substring(0, 10)}</div>
        <div className="basis-1/12 h-14 rounded-lg flex items-center justify-start ">{props.price}</div>
      </div>
    </div>
  );
};

export default ProjectRow;
