import React from "react";
import { FieldProps } from "formik";
import Select, { Options, OnChangeValue } from "react-select";
import { Country } from "../connector/models/apiModels";

interface FormikSelectProps extends FieldProps {
  options: Options<Country>;
  isMulti?: boolean;
}

export const FormikSelect = ({ field, form, options, isMulti = false }: FormikSelectProps) => {
  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      color: "black",
      backgroundColor: "white",
    }),
    control: (provided: any) => ({
      ...provided,
      marginTop: "0",
      length: "100px",
      borderRadius: "6px",
      height: "46px",
    }),
    container: (provided: any) => ({
      ...provided,
      width: "100%",
      fontFamily: "Heebo",
    }),
  };
  const onChange = (option: OnChangeValue<Country | Country[], boolean>) => {
    form.setFieldValue(field.name, isMulti ? (option as Country[]).map((item: Country) => item.id) : (option as Country).id);
  };

  return (
    <Select
      name={field.name}
      styles={customStyles}
      placeholder={"Country"}
      value={field.value != null ? options.find((x) => x.id === field.value) : null}
      getOptionLabel={(c) => c.countryName}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
      noOptionsMessage={() => "Select a country"}
    />
  );
};
