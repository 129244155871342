import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, Fade } from "@mui/material";
import { AppDispatch, RootState } from "../../store/store";
import { connect } from "react-redux";
import { FileState, getFileState } from "../../reducers/file.slice";
import { ControlTypes } from "../../types/StandardFilesTranslateTypes";
import { controlActions, getControlState } from "../../reducers/control.slice";

interface DuplicatedFilesModalProps {
  file: FileState;
  controls: ControlTypes;

  onCloseClick(show: boolean): void;
}

const mapStateToProps = (state: RootState) => {
  return {
    file: getFileState(state),
    controls: getControlState(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onCloseClick: (show: boolean) => dispatch(controlActions.showSameFilesNameModal(show)),
  };
};

const DuplicatedFilesModal: React.FC<DuplicatedFilesModalProps> = (props: DuplicatedFilesModalProps) => {
  const onUploadNewFIleClick = () => {
    sessionStorage.clear();
    document.location.reload();

    let userLogin = "";
    let userPass = "";
    const logins: string[] = ["test", "test2", "test3"];
    const passwords: string[] = ["qwe123", "test123"];

    function checkIfExist(login: string, password: string) {
      let isLoginExist = logins.some((loginsItem) => loginsItem == login); // returns true or false
      let isPasswordExists = passwords.some((passwordItem) => passwordItem == password); // returns true or false;

      if (isLoginExist && isPasswordExists) {
        console.log("Welcome");
      } else {
        console.log("I dont know you");
      }
    }
  };
  return (
    <Modal open={props.controls.showSameFilesNameModal}>
      <Fade in={props.controls.showSameFilesNameModal}>
        <Box className="word-allowance-modal">
          <div className="word-allowance-modal-content mx-4">
            <div className="word-allowance-modal-description">
              You have already uploaded a file <br /> with this name.
            </div>
            <div className="text-center">
              <Button style={{ color: "whitesmoke" }} variant={"outlined"} onClick={() => props.onCloseClick?.(false)}>
                Close
              </Button>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicatedFilesModal);
