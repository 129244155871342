import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, Fade } from "@mui/material";
import { AppDispatch, RootState } from "../../store/store";
import { connect } from "react-redux";
import { FileState, generatePayForMachineTranslationAction, getFileState } from "../../reducers/file.slice";
import { ControlTypes } from "../../types/StandardFilesTranslateTypes";
import { getControlState } from "../../reducers/control.slice";
import { removeSessionStorageItems } from "../../helpers/RedirectHelpers";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface WordsAllowanceWarningModalProps {
  file: FileState;
  controls: ControlTypes;

  onPayButtonClick?(): void;
}

const mapStateToProps = (state: RootState) => {
  return {
    file: getFileState(state),
    controls: getControlState(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onPayButtonClick: () => dispatch(generatePayForMachineTranslationAction()),
  };
};

const style = {};
const WordsAllowanceWarningModal: React.FC<WordsAllowanceWarningModalProps> = (props: WordsAllowanceWarningModalProps) => {
  const onUploadNewFIleClick = () => {
    removeSessionStorageItems();
    document.location.reload();
  };
  return (
    <Modal open={props.controls.showWordAllowanceWarning}>
      <Fade in={props.controls.showWordAllowanceWarning}>
        <Box sx={style} className="word-allowance-modal">
          <div className="absolute top-4 right-4" style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faXmark} size={"2x"} onClick={() => onUploadNewFIleClick()} />
          </div>
          <div className="word-allowance-modal-content mx-4">
            <div className="word-allowance-modal-description">It seems that you have already taken advantage of a free offer. The cost for this translation service is 
            {" "}{props.file.translationCost}€. Would you like to proceed?</div>
            <div className="word-allowance-modal-buttons mx-auto">
              <Button variant={"outlined"} onClick={() => props.onPayButtonClick?.()}>
                Pay for translation
              </Button>
              {/*<Button variant={"outlined"} onClick={() => onUploadNewFIleClick()}>*/}
              {/*  Upload new file*/}
              {/*</Button>*/}
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WordsAllowanceWarningModal);
