import React, { SyntheticEvent, useState } from "react";
import { RootState } from "../../store/store";
import { FileState, getFileState } from "../../reducers/file.slice";
import { connect } from "react-redux";
import { Button } from "@mui/material";
import { Box, Rating, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import Modal from "@mui/material/Modal";

interface TranslationSuccessMobileModalProps {
  file: FileState;
}

const mapStateToProps = (state: RootState) => {
  return {
    file: getFileState(state),
  };
};

const style = {
  // padding: "1rem",
  position: "absolute",
  top: "20%",
  width: "100%",
  bgcolor: "background.paper",
  fontFamily: "Heebo",
};

const TranslationSuccessMobileModal: React.FC<TranslationSuccessMobileModalProps> = (props) => {
  const [value, setValue] = useState(0);
  const onRatingChange = (e: SyntheticEvent, newValue: number | null) => {
    setValue(newValue as number);
  };
  return (
    <Modal open={true} className="modal-mobile animate__animated animate__fadeInDownBig" aria-labelledby={"modal-modal-title"} aria-describedby={"modal-modal-description"}>
      <Box sx={style}>
        <Box className={"modal-mobile-translation-section"}>
          <Typography id="modal-modal-title" variant="h2" component="h2">
            Thank you!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 5, fontFamily: "Heebo", color: "grey" }} component="span">
            We received the payment and are <br />
            starting to work on your project <br />
            The project number is: %Project_ID%
          </Typography>
        </Box>
        <Box className={"modal-mobile-rate-section mt-5"}>
          <Typography id="modal-modal-title" variant="h3" component="h2">
            Do you like what you see ?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Rating
              sx={{ mt: 5 }}
              value={value}
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
              onChange={(event, newValue) => {
                onRatingChange(event, newValue as number);
              }}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 5 }}>
            <Button variant={"text"} sx={{ color: "white" }}>
              START ANOTHER JOB
            </Button>
            <Button variant={"text"} sx={{ color: "white" }}>
              GO TO DASHBOARD
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default connect(mapStateToProps)(TranslationSuccessMobileModal);
