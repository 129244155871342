
// noinspection ES6UnusedImports

import React, { useEffect, useState } from "react";
import "./App.css";
import { useIdleTimer } from "react-idle-timer";
import Navbar from "./components/Navbar";
import Footer from "./components/newFooter/Footer";
import WordsAllowanceWarningModal from "./components/modals/WordsAllowanceWarningModal";
import DuplicatedFilesModal from "./components/modals/DuplicatedFilesModal";
import ErrorModal from "./components/modals/ErrorModal";
import PdfMixingWarningModal from "./components/modals/PdfMixingWarningModal";
import { hotjar } from "react-hotjar";
import { Routes, Route } from "react-router-dom";
import Profile from "./components/Profile";
import { removeSessionStorageItems } from "./helpers/RedirectHelpers";
import ProjectList from "./components/ProjectsList";
import ProtectedRoute, { ProtectedRouteProps } from "./components/ProtectedRoute";
import HomeScreen from "./components/HomeScreen";
import Subscription from "./components/Subscription";
import { RootState } from "./store/store";
import { connect } from "react-redux";
import { FadeLoader } from "react-spinners";
 import PricingScreen from './components/PricingScreen';
import FAQ from "./components/FAQ";

interface AppProps {
    isLoading: boolean;
}

const mapsStateToProps = (state: RootState) => {
    return{
        isLoading: state.control.isSSOLoading
    }
}
const App: React.FC<AppProps> = (props) => {
  const timeout = 1000 * 60 * 35;
  const promptBeforeIdle = 4_000;
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    isAuthenticated: sessionStorage.getItem("anonymous") == "false",
    authenticationPath: "/",
  };

  hotjar.initialize(3402008, 6);
  if (window.performance.navigation.type == 1 || window.performance.navigation.type == 2) {
    removeSessionStorageItems();
  }
  const [remaining, setRemaining] = useState<number>(timeout);

  const onPrompt = () => {
    sessionStorage.clear();
  };
  hotjar.event("button-click");
  const { getRemainingTime, activate } = useIdleTimer({
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  return (
    <>
      <div className="container-fluid" style={{ background: "#251cc9" }}>
        <div className="container">
          <div className="row">
            <Navbar />
          </div>
        </div>
      </div>
        {props.isLoading ? (<div className="container container-xxl h-screen bg-[#d4eae7] flex justify-center items-center"><FadeLoader color={"#2667c9"}/></div>) :
            (
                <Routes>
                    <Route path="/" element={<HomeScreen />}/>
                    <Route path="/uploadfile" element={<HomeScreen />}/>
                    <Route path="/FAQs-how-to-translate-PDF-files" element={<FAQ/>} />
                    <Route path="/Pricing" element={<PricingScreen />}/>
                    <Route
                        path={"/profile"}
                        element={
                            <ProtectedRoute
                                {...defaultProtectedRouteProps}
                                outlet={
                                    <div className="container container-xxl">
                                        <Profile />
                                    </div>
                                }
                            />
                        }
                    />
                    <Route
                        path={"/orders"}
                        element={
                            <ProtectedRoute
                                {...defaultProtectedRouteProps}
                                outlet={
                                    <div className="container container-xxl">
                                        <ProjectList />
                                    </div>
                                }
                            />
                        }
                    />
                    <Route
                        path={"/subscription"}
                        element={
                            <ProtectedRoute
                                {...defaultProtectedRouteProps}
                                outlet={
                                    <div className="container container-xxl">
                                        <Subscription />
                                    </div>
                                }
                            />
                        }
                    />
                </Routes>
            )}

      <Footer />
      <WordsAllowanceWarningModal />
      <DuplicatedFilesModal />
      <ErrorModal />
      <PdfMixingWarningModal />
    </>
  );
}

export default connect(mapsStateToProps) (App);

function Layout() {
  return (
    <>
      <Footer />
      <WordsAllowanceWarningModal />
      <DuplicatedFilesModal />
      <ErrorModal />
      <PdfMixingWarningModal />
    </>
  );
}
