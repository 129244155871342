import {store} from "../store/store"
import Cookies from "universal-cookie"

const cookies = new Cookies()

export function addCurrentEditorState(){
  const state = store.getState()


  cookies.set("currentStep", (state.control.step + 1).toString(), {path: '/'})
  cookies.set("projectId", state.file.projectId, {path:'/'})
  if(state.languages.sourceLanguage){
    cookies.set("sourceLang", state.languages.sourceLanguage)
    cookies.set("targetLang", state.languages.targetLanguages)
  }
  cookies.set("account", String(state.control.isHasAccount))
  cookies.set("service", state.file.service)
}

export function clearCookies(){
  cookies.remove("projectId")
  cookies.remove("currentStep")
  cookies.remove("sourceLang")
  cookies.remove("targetLang")
  cookies.remove("account")
  cookies.remove("service")

}
