import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SubscriptionType } from "../types/StandardFilesTranslateTypes";
import { RootState } from "../store/store";
import { ClientSubscription, SubscribePlan } from "../connector/models/apiModels";
import { WORD_ALLOWANCE } from "../editorConstants";


export const SUBSCRIPTION_FEATURE_KEY = "subscription";
export const generateSubscriptionBuyAction = createAction("subscription/generateSubscriptionBuyAction");
export const generateGetSubscriptionDataAction = createAction("subscription/generateGetSubscriptionDataAction");
export const generateSubscriptionCancelAction = createAction("subscription/generateSubscriptionCancelAction");

export const generateSubscriptionUpgradeBuyAction = createAction<SubscribePlan>("subscription/generateSubscriptionUpgradeBuyAction");

const initState: SubscriptionType = {
  isActive: false,
  dataLoaded: false,
  subData: { pdfPagesAvailable: 20, wordsAvailable: WORD_ALLOWANCE } as ClientSubscription,
};

export const subscriptionSlice = createSlice({
  name: SUBSCRIPTION_FEATURE_KEY,
  initialState: initState,
  reducers: {
    setSubData: (state, action: PayloadAction<ClientSubscription>) => {
      state.subData = action.payload;
      state.isActive = action.payload.subscriptionName == "Membership";

      state.dataLoaded = true;
    },
    setSubscriptionState: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
  },
});

/*
 * Export reducer for store configuration.
 */
export const subscriptionReducer = subscriptionSlice.reducer;

export const subscriptionActions = subscriptionSlice.actions;

export const getSubscriptionState = (rootState: RootState): SubscriptionType => rootState[SUBSCRIPTION_FEATURE_KEY];
