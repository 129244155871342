import {
  createAction,
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { Address, Company, TaxTypeUpdate, User, UserRegisterInfo } from "../types/StandardFilesTranslateTypes";
import { CheckUserWordsAllowanceResponse, Country, GetUserInfoResponse, TaxType } from "../connector/models/apiModels";
import { PDF_PAGES_ALLOWANCE, WORD_ALLOWANCE } from "../editorConstants";
import { UpdateTaxNumberAction } from "../actions/actionsTypes";
import { TUConnector } from "../connector/TUConnector";
import { axiosClient } from "../services/axiosConfig";
import { AxiosResponse } from "axios";
import { stat } from "fs";

export const USER_FEATURE_KEY = "user";

export const generateUserRegisterAction = createAction("user/generateUserRegisterAction");
export const generateUpdateUserDataAction = createAction("user/generateUpdateUserDataAction");
export const generateUpdateUserTaxTypeAction = createAction("user/generateUpdateUserTaxTypeAction");
export const generateGetUserData = createAction("users/generateGetUserData");

export interface UserEntity {
}

export interface UserState extends EntityState<UserEntity> {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  address: Address;
  company: Company;
  pdfPagesAllowance: number;
  wordsAllowance: number;
  isDataLoaded: boolean;
  taxTypeCode: string;
  taxNumber: string;
  countryId: number;
  countries: Country[];
}

export const userAdapter = createEntityAdapter<UserEntity>();

export const initialUserState: UserState = userAdapter.getInitialState({
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  address: {} as Address,
  company: {} as Company,
  pdfPagesAllowance: PDF_PAGES_ALLOWANCE,
  wordsAllowance: WORD_ALLOWANCE,
  countryId: 0,
  isDataLoaded: false,
  taxTypeCode: "",
  taxNumber: "",
  countries: [],
});

export const languagesSlice = createSlice({
  name: USER_FEATURE_KEY,
  initialState: initialUserState,
  reducers: {

    setCountryId: (state, action: PayloadAction<number>) => {
        state.countryId = action.payload;
    },
    setTaxType: (state, action: PayloadAction<string>) => {
      state.taxTypeCode = action.payload;
    },
    setTaxNumber: (state, action: PayloadAction<string>) => {
      state.taxNumber = action.payload;
    },
    setTaxInfo: (state, action: PayloadAction<{ taxCode: string; taxNumber: string }>) => {
      state.taxTypeCode = action.payload.taxCode;
      state.taxNumber = action.payload.taxNumber;
      state.isDataLoaded = true;
    },
    setUserData: (state, action: PayloadAction<User>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.address = action.payload.address;
      state.company = action.payload.company;
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUserAllowance: (state, action: PayloadAction<CheckUserWordsAllowanceResponse>) => {
      state.pdfPagesAllowance = action.payload.pdfPagesAvailable;
      state.wordsAllowance = action.payload.wordsAvailable;
    },
    addUserRegisterInfo: (state, action: PayloadAction<UserRegisterInfo>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.password = action.payload.password;
    },
    addCompanyRegisterInfo: (state, action: PayloadAction<{ address: Address; company: Company }>) => {
      state.address = action.payload.address;
      state.company = action.payload.company;
    },
    setCountries: (state, action: PayloadAction<Country[]>) => {
      state.countries = action.payload;
    },
  },
});

/*
 * Export reducer for store configuration.
 */
export const userReducer = languagesSlice.reducer;

export const userActions = languagesSlice.actions;

const { selectAll, selectEntities } = userAdapter.getSelectors();

export const getUserState = (rootState: RootState) => rootState[USER_FEATURE_KEY];
