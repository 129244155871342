import axios from "axios";
import * as Sentry from "@sentry/react";
export const axiosClient = axios.create();

axiosClient.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem("token");
    if (token != "null") {
      // @ts-ignore
      config.headers["Authorization"] = `Bearer ${token}`;
      // @ts-ignore
      config.headers["x-textunited-addin"] = "tuft";
      // @ts-ignore
      config.headers["Content-Type"] = "application/json";

      config.baseURL = "https://api.textunited.com/";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.message == "canceled") {
      window.location.reload();
    }
    const originalRequest = error.config;
    if (error.response && (error.response.status === 401) && !originalRequest._retry) {
      originalRequest._retry = true;

      let refresh_token = sessionStorage.getItem("refresh_token");
      const access_token = await axios({
        method: "post",
        url: "https://api.textunited.com/authentication/api/RefreshToken",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          token: refresh_token,
        },
      });
      sessionStorage.setItem("token", access_token.data.AccessToken);
      sessionStorage.setItem("refresh_token", access_token.data.RefreshToken);
      sessionStorage.setItem("anonymous", "false");
      axios.defaults.headers.common["Authorization"] = `Bearer ${access_token.data.AccessToken}`;
      return axiosClient(originalRequest);
    }
    Sentry.captureException(error, {extra:{code: error.response?.status}});
    return Promise.reject(error);
  }
);
