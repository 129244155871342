/*Translation options*/

export const MACHINE_TRANSLATION_NAME = "Instant";
export const MACHINE_TRANSLATION_AND_REVIEW = "Rapid";
export const HUMAN_TRANSLATION_AND_REVISION = "ISO";

/*Tool tip messages*/
export const DIFFERENT_LANGUAGE_WARNING =
  "Different source languages are auto-detected, but only one source language is allowed. Delete some files from the list or, if the auto-detect was incorrect, set up the correct source language in the next step.";

/*Modal error messages*/
export const INTERNAL_SERVER_ERROR = "We encountered an error. Try again. If the problem persists, please get in touch with us at: support@textunited.com";

/*Loading screen messages*/
export const PREPARING_COST_ESTIMATION = "Preparing and estimating the file(s)";
export const REDIRECT_TO_STRIPE = "Redirecting to Stripe checkout";
export const CHECKING_EMAIL = "Checking email";
export const CREATING_ACCOUNT = "Creating your account in Text United";
export const ACCOUNT_WAS_CREATED = "Account was successfully created, now you will be redirected to Stripe checkout";
export const PREPARING_MACHINE_TRANSLATION = "Almost done. \n" + "                  It can take a moment, depending on size of your file(s).";
export const PROJECT_OFFER_REQUIRED = "Sending project to Text United";

/*Free word allowance */
export const WORD_ALLOWANCE = 10000;
export const PDF_PAGES_ALLOWANCE = 20;

/*Subscription plans*/
export const subscriptions: string[] = ["Free pan", "Membership"];
export const subscriptionPrice = 6;
