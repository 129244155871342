export const checkToken = (token: string) => {
  if (sessionStorage.getItem("anonymous") == "true") {
    return;
  }
  if (token == "null" || token == null || token == "") {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  const t = JSON.parse(jsonPayload);
  const t3 = t.User.split(",");
  const userLogin = JSON.parse(t3);

  return userLogin.Login;
};

export function removeParamsFromUrl(paramsToRemove: string[]): void {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search.slice(1));
  const urlWithoutParams = url.origin + url.pathname;

  for (const param of paramsToRemove) {
    params.delete(param);
  }
  //window.history.replaceState({},'',window.location.hash)
  //window.history.replaceState({}, "", `${window.location.pathname}?${params}${window.location.hash}`);
  window.history.replaceState({}, "", urlWithoutParams);
}
