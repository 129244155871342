import { createAction, createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { DetectedLanguage, LanguagesTypes } from "../types/StandardFilesTranslateTypes";
import { Country } from "../connector/models/apiModels";

export const LANGUAGES_FEATURE_KEY = "languages";

/*Generators*/
export const detectLanguageGenerator = createAction("languages/detectLanguageGenerator", (text) => text);
export const generatorFetchLanguages = createAction("languages/generatorFetchLanguages");
export const generateGetCostEstimation = createAction("languages/generateGetCostEstimation");

/*
 * Update these interfaces according to your requirements.
 */
export interface LanguagesEntity extends LanguagesTypes {}

export interface LanguagesState extends EntityState<LanguagesEntity> {
  isLanguageDetected: boolean;
  languageDetectedId: number;
  languageDetectedGeneralCode: string;
  sourceLanguage: string;
  targetLanguages: string[];
  countries: Country[];
}

export const languagesAdapter = createEntityAdapter<LanguagesEntity>({
  selectId: (language) => language.id,
});

export const initialLanguagesState: LanguagesState = languagesAdapter.getInitialState({
  isLanguageDetected: false,
  languageDetectedId: 0,
  languageDetectedGeneralCode: "",
  sourceLanguage: "",
  targetLanguages: [],
  countries: [],
});

export const languagesSlice = createSlice({
  name: LANGUAGES_FEATURE_KEY,
  initialState: initialLanguagesState,
  reducers: {
    addCountries: (state, action: PayloadAction<Country[]>) => {
      state.countries = action.payload;
    },
    addLanguages: (state, action: PayloadAction<LanguagesEntity[]>) => {
      languagesAdapter.addMany(state, action.payload);
    },
    resetDetectedLanguage: (state, action: PayloadAction<DetectedLanguage>) => {
      state.isLanguageDetected = false;
    },
    detectLanguage: (state, action: PayloadAction<DetectedLanguage>) => {
      state.languageDetectedId = action.payload.langId;
      state.isLanguageDetected = true;
      state.sourceLanguage = action.payload.langCode;
      state.languageDetectedGeneralCode = action.payload.langCode.substring(0, 2);
    },
    addSourceLanguage: (state, action: PayloadAction<string>) => {
      state.sourceLanguage = action.payload;
    },
    addTargetLanguage: (state, action: PayloadAction<string[]>) => {
      state.targetLanguages = action.payload;
    },
    addSourceLang: (state, action: PayloadAction<string>) => {
      state.sourceLanguage = action.payload;
    },
  },
});

/*
 * Export reducer for store configuration.
 */
export const languagesReducer = languagesSlice.reducer;

export const languagesActions = languagesSlice.actions;

const { selectAll, selectEntities } = languagesAdapter.getSelectors();

export const getLanguagesState = (rootState: RootState) => rootState[LANGUAGES_FEATURE_KEY];

export const selectAllLanguages = createSelector(getLanguagesState, selectAll);

export const selectLanguagesEntities = createSelector(getLanguagesState, selectEntities);
