import { TUConnector } from "../connector/TUConnector";
import { store } from "../store/store";
import { userActions } from "../reducers/user.slice";
import { subscriptionActions } from "../reducers/subscription.slice";
import { projectsActions } from "../reducers/projects.slice";
import { HUMAN_TRANSLATION_AND_REVISION, MACHINE_TRANSLATION_AND_REVIEW, MACHINE_TRANSLATION_NAME } from "../editorConstants";

const conn = new TUConnector();

export function getStoredValue(key: string): string | null {
  const stored = sessionStorage.getItem(key);
  return stored == null ? null : stored;
}

export function setStoredValue(key: string, value: any) {
  sessionStorage.setItem(key, value);
}

export async function getUserAllowanceOnLogIn() {
  if (getStoredValue("anonymous") == "false") {
    await getUserSubscriptionData();
    await conn.checkUserWordsAllowance().then((resp) => {
      store.dispatch(userActions.setUserAllowance(resp.data));
    });
  }
}

export async function getUserSubscriptionData() {
  if (getStoredValue("anonymous") == "false") {
    await conn.checkUserWordsAllowance().then((resp) => {
      store.dispatch(subscriptionActions.setSubData(resp.data));
    });
    await conn.getProjectList().then((resp) => {
      store.dispatch(projectsActions.addProject(resp.data));
    });
  }
}

export function convertServiceNameToServiceOption(service: string): number {
  let option = 0;
  switch (service) {
    case MACHINE_TRANSLATION_NAME:
      option = 1;
      break;
    case MACHINE_TRANSLATION_AND_REVIEW:
      option = 2;
      break;
    case HUMAN_TRANSLATION_AND_REVISION:
      option = 3;
      break;
  }
  return option;
}
