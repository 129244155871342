import React from 'react';
import { Modal, Box, Typography, Button, Fade } from '@mui/material'; // Import the necessary components
import { map } from 'jquery';
import { AppDispatch, RootState } from '../../store/store';
import { controlActions } from '../../reducers/control.slice';
import { connect } from 'react-redux';
import { generateSubscriptionCancelAction } from '../../reducers/subscription.slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface SubscriptionCancelModalProps {
    open: boolean;
    onShowSubscriptionCancelModal: (show: boolean) => void;
    onSubscriptionCancelClick(): void;
}

const mapStateToProps = (state: RootState) => {

    return {
        open: state.control.showSubscriptionCancelModal,
    };
}
const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        onShowSubscriptionCancelModal: (show: boolean) => dispatch(controlActions.showSubscriptionCancelModal(show)),
        onSubscriptionCancelClick: () => dispatch(generateSubscriptionCancelAction()),
    };
};

const SubscriptionCancelModal: React.FC<SubscriptionCancelModalProps> = ({
    open, 
    onShowSubscriptionCancelModal,
    onSubscriptionCancelClick,
}) => {

    const handleClose = () => {
        onShowSubscriptionCancelModal(false);
    }; 
    const handleOk = () => {
        onSubscriptionCancelClick();
        onShowSubscriptionCancelModal(false);
    }
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#a199ff',
        border: '1px solid #000',
        borderRadius: '4px',
        color: 'white',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
        p: 4,
    }; 

    return(
        <Modal open={open}>
      <Fade in={open}>
        <Box sx={style} className="word-allowance-modal">
          <div className="absolute top-4 right-4" style={{ cursor: "pointer" }}>
            {/* <FontAwesomeIcon icon={faXmark} size={"2x"} onClick={() => onUploadNewFIleClick()} /> */}
          </div>
          <div className="word-allowance-modal-content mx-4">
            <div className="word-allowance-modal-description">
                Are you sure you want to cancel the subscription?
            </div>
            <div className="word-allowance-modal-buttons mx-2">
              <Button variant={"outlined"} onClick={() => handleClose()}>
                Close
              </Button>
              <Button variant={"outlined"} onClick={() => handleOk()}>
                Yes
              </Button>
              {/*<Button variant={"outlined"} onClick={() => onUploadNewFIleClick()}>*/}
              {/*  Upload new file*/}
              {/*</Button>*/}
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
    )
};

export default connect(mapStateToProps, mapDispatchToProps) (SubscriptionCancelModal); // Export the component