import React, { useState } from "react";
import { AppDispatch, RootState } from "../store/store";
import { generatePrepareOrderForNotLoggedInUserAction, generatePrepareOrderLoggedInUserAction, getControlState } from "../reducers/control.slice";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { ControlTypes, SubscriptionType } from "../types/StandardFilesTranslateTypes";
import { CostEstimationResponse } from "../connector/models/apiModels";
import { FileState, getFileState } from "../reducers/file.slice";
import LoadingScreen from "./LoadingScreen";
import ErrorModal from "./modals/ErrorModal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// @ts-ignore fix this later
import { gtag } from "ga-gtag";
import { MACHINE_TRANSLATION_NAME, WORD_ALLOWANCE } from "../editorConstants";
import { Tooltip } from "@mui/material";
import { getUserState, UserState } from "../reducers/user.slice";
import { getSubscriptionState } from "../reducers/subscription.slice";

interface HowToTranslateProps {
  costEstimation: CostEstimationResponse;
  control: ControlTypes;
  file: FileState;
  user: UserState;
  subscription: SubscriptionType;

  nextStepForNotLoggedUser?(price: string): void;

  nextStepLoggedUser?(price: string): void;
}

const mapStateToProps = (state: RootState): HowToTranslateProps => {
  return {
    control: getControlState(state),
    costEstimation: getFileState(state).costEstimations,
    file: getFileState(state),
    user: getUserState(state),
    subscription: getSubscriptionState(state),
  };
};

const mapDispatchToProp = (dispatch: AppDispatch) => {
  return {
    nextStepForNotLoggedUser: (price: string) => dispatch(generatePrepareOrderForNotLoggedInUserAction({ price })),
    nextStepLoggedUser: (price: string) => dispatch(generatePrepareOrderLoggedInUserAction({ price })),
  };
};

const HowToTranslate: React.FC<HowToTranslateProps> = (props: HowToTranslateProps) => {
  const [isActive, setActive] = useState("-active");

  const onOrderButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const data = e.currentTarget.value.split(",");

    if (props.file.totalWordCount < WORD_ALLOWANCE && data[1] == MACHINE_TRANSLATION_NAME) {
      data[0] = "free";
    }
    gtag("event", "select_content", { content_type: data[1], item_id: data[1], final_price: data[0] });
    const anon = sessionStorage.getItem("anonymous");
    if (anon == "false") {
      props.nextStepLoggedUser!(e.currentTarget.value);
    } else {
      props.nextStepForNotLoggedUser!(e.currentTarget.value);
      sessionStorage.setItem("step", "4");
    }
  };

  return (
    <>
      {props.file.isLoading ? (
        <LoadingScreen />
      ) : (
        <div className={`card translation-options animate__animated ${props.control.animation}`}>
          <ErrorModal />
          <div className="px-5" style={{ height: "100%" }} hidden={props.control.showError}>
            <div className="row gx-5 my-4" style={{ height: "100%" }}>
              <div
                className={`col-10 col-sm  mx-4 p-3 d-flex flex-column border bg-light translation-options-container translation-options-container${isActive} animate__animated`}
                aria-label={"Instant"}
                // onClick={(e) => {
                //   let elem: HTMLElement = document.getElementsByClassName("banner-btn ")[0] as HTMLElement;
                //   elem.click();
                // }}
              >
                <div className="options-header" aria-label={"Instant"}>
                  <h1>
                    Instant{" "}
                    <Tooltip style={{ color: "#71a5e1" }} disableFocusListener enterTouchDelay={0} title={"Get the translations instantly. The translated files are formatted as your source documents."}>
                      <HelpOutlineIcon />
                    </Tooltip>
                  </h1>
                  <p>Automatic translation</p>
                </div>
                <div className="option-description" aria-label={"Instant"}></div>
                <div className="text-center options-bottom mt-auto p-2" aria-label={"Instant"}>
                  <div className="options-text">
                    <p>
                      {props.file.totalWordCount > props.subscription.subData.wordsAvailable || props.costEstimation.pdfPageCount > props.subscription.subData.pdfPagesAvailable ? (
                        Math.round(props.costEstimation.machineTranslationCost * 100) / 100 + "€"
                      ) : (
                        <>Free</>
                      )}
                      {/*{props.file.totalWordCount > props.subscription.subData.wordsAvailable ? (*/}
                      {/*  <>*/}
                      {/*    <div style={{ lineHeight: "20px" }}>*/}
                      {/*      <span>Free {props.subscription.subData.wordsAvailable} words discount included.</span>*/}
                      {/*    </div>*/}
                      {/*  </>*/}
                      {/*) : null}*/}
                      {/*{props.costEstimation.pdfPageCount >= props.subscription.subData.pdfPagesAvailable ? (*/}
                      {/*  <>*/}
                      {/*    <div style={{ lineHeight: "20px" }}>*/}
                      {/*      <span>Free {props.subscription.subData.pdfPagesAvailable} page discount included.</span>*/}
                      {/*    </div>*/}
                      {/*  </>*/}
                      {/*) : null}*/}
                    </p>
                  </div>

                  <Button value={Math.round(props.costEstimation.machineTranslationCost * 100) / 100 + "," + "Instant"} className="banner-btn mt-3" onClick={(e) => onOrderButtonClick(e)}>
                    {props.costEstimation.isValid ? "GET IT" : "GET IT"}
                  </Button>
                </div>
              </div>
              <div
                className="col-10 col-sm mx-4 p-3 d-flex d-sm-flex flex-column flex-sm-column flex-lg-column border bg-light translation-options-container"
                // onClick={(e) => {
                //   let elem: HTMLElement = document.getElementsByClassName("banner-btn ")[1] as HTMLElement;
                //   elem.click();
                // }}
                onMouseMove={() => setActive("")}
              >
                <div className="options-header">
                  <h1>
                    Rapid{" "}
                    <Tooltip style={{ color: "#71a5e1" }} disableFocusListener enterTouchDelay={0} title={"Get the translation within a few hours. A native translator will verify and correct all translation errors."}>
                      <HelpOutlineIcon />
                    </Tooltip>
                  </h1>
                  <p>Human post-editing</p>
                </div>
                <div className="option-description"></div>
                <div className="text-center options-bottom mt-auto p-2">
                  <div className="options-text">
                    <p>{props.costEstimation.isValid ? Math.round(props.costEstimation.machineTranslationAndReviewCost * 100) / 100 + "€" : null}</p>
                  </div>
                  <Button value={Math.round(props.costEstimation.machineTranslationAndReviewCost * 100) / 100 + "," + "Rapid"} className="banner-btn" onClick={(e) => onOrderButtonClick(e)}>
                    {props.costEstimation.isValid ? "BUY" : "ASK FOR OFFER"}
                  </Button>
                </div>
              </div>
              <div
                className="col-10 col-sm mx-4 p-3 d-flex flex-column  border bg-light translation-options-container icon"
                // onClick={(e) => {
                //   let elem: HTMLElement = document.getElementsByClassName("banner-btn")[2] as HTMLElement;
                //   elem.click();
                // }}
                onMouseMove={() => setActive("")}
              >
                <div className="options-header">
                  <h1>
                    ISO <span>17100</span>{" "}
                    <Tooltip
                      style={{ color: "#71a5e1" }}
                      disableFocusListener
                      enterTouchDelay={0}
                      title={"Get the translation within 24 hours. A specialized team of professional translators will translate under the supervision of a project manager according to a standard for regulated industries."}
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </h1>
                  <p>Human translation with revision</p>
                </div>
                <div className="option-description"></div>
                <div className="text-center options-bottom mt-auto p-2">
                  <div className="options-text">
                    {/*Uncomment this*/}
                    <p>{props.costEstimation.isValid ? Math.round(props.costEstimation.humanTranslationAndRevisionCost * 100) / 100 + "€" : null}</p>
                  </div>
                  <Button value={Math.round(props.costEstimation.humanTranslationAndRevisionCost * 100) / 100 + "," + "ISO"} className="banner-btn" onClick={(e) => onOrderButtonClick(e)}>
                    {props.costEstimation.isValid ? "BUY" : "ASK FOR OFFER"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProp)(HowToTranslate);
