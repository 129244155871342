import React from "react";
import {ReactComponent as NonActiveIcon} from "../../assets/stepper/stepper-no-active.svg";
import SvgIcon from "@mui/material/SvgIcon";

const NotActiveIcon = () => (
  <SvgIcon>
    <NonActiveIcon />
  </SvgIcon>
);

export default NotActiveIcon;
